<template>
  <div class="content contact-list">
    <!-- <div class="testing-container">
      <button class="btn btn-primary" @click="fetch(1)">Toggle fetch</button>
      <p>isDifferent: {{isDifferent}}</p>
    </div> -->
    <PageHeader title="Contatos" />
    <!--/Search column -->
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <ul class="nav nav-tabs">
            <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'contacts', fetch(1)">
              <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'contacts' }">
                <span class="material-symbols-outlined"> person </span> Contatos
              </a>
            </li>
            <li class="nav-item c-pointer" role="presentation" @click="tabLink = 'groups', openGroup(lastGroup)">
              <a class="nav-link" :class="{ 'router-link-exact-active router-link-active': tabLink == 'groups' }">
                <span class="material-symbols-outlined"> group </span> Grupos
              </a>
            </li>
            <div class="opts_card">
            </div>
          </ul>
        </div>
      </div>
      <div class="opt-tags hide" v-if="tabLink == 'contacts'">
        <div v-if="groups" class="tags-wrapper">
          <div class="icon"><span id="left" class="material-symbols-outlined">keyboard_arrow_left</span></div>
          <ul id="tabs-box" class="tabs-box">
            <li class="tab" :class="{'active': tabActive == 0}" @click="resetTabs()">{{$t('generic-str.all')}}</li>
            <li class="tab" :class="{'active': checkItensTab(i)}" v-for="(group, i) in groups" :key="i">
              <span @click="addRemoveItensTab(i)">{{group.name}}</span>
            </li>
          </ul>
          <div class="icon" :style="totalWidth > maxWidth ? 'display: flex;' : 'display: none;'"><span id="right" class="material-symbols-outlined">keyboard_arrow_right</span></div>
        </div>
        <div class="btn-settings"><router-link to="/contacts/groups"><span class="material-symbols-outlined">settings</span></router-link></div>
      </div>
      <div class="new-contacts">
        <div class="all-actions d-flex justify-content-between">
          <div class="action" v-if="contacts && tabLink == 'contacts'">
            <div class="control-group opt-2 to-left">
            <label
              class="control control-checkbox"
              :for="'template-checka-all'"
            >
              <input
                v-model="selectAllContacts"
                type="checkbox"
                class="custom-control-input"
                :id="'template-checka-all'"
              />
              <div class="control_indicator"></div>
            </label>
            </div>
            {{$t('generic-str.all')}}
          </div>
          <div class="action" v-if="contacts && tabLink == 'contacts'">
            <button @click="deleteAll" class="btn-delete-all">
              <span class="material-symbols-outlined to-left">delete_forever</span>
              <span>
                <!-- {{selectedContacts.length}}
                <template v-if="selectedContacts.length > 1 || selectedContacts.length === 0">
                  selecionados
                </template>
                <template v-else>
                  selecionado
                </template> -->
                {{$tc('app.selected', selectedContacts.length, {length: selectedContacts.length})}}
              </span>
            </button>
          </div>
          <div class="action" v-if="contacts && tabLink == 'groups'">
            <div class="control-group opt-2 to-left">
            <label
              class="control control-checkbox"
              :for="'template-checka-all'"
            >
              <input
                v-model="selectAllGroups"
                type="checkbox"
                class="custom-control-input"
                :id="'template-checka-all'"
              />
              <div class="control_indicator"></div>
            </label>
            </div>
            {{$t('generic-str.all')}}
          </div>
          <div class="action" v-if="contacts && tabLink == 'groups'">
            <button @click="deleteAllGroups" class="btn-delete-all">
              <span class="material-symbols-outlined to-left">delete_forever</span>
              <span>
                {{$tc('app.selected', selectedGroups.length, {length: selectedGroups.length})}}
              </span>
            </button>
          </div>
          <div class="extra send-campaign">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none p-0"
              no-caret
              right
              class="dropdown-menu-contact">
              <template #button-content>
                <img src="assets/img/icons/svg/campanha.svg" class="c-pointer"/>
              </template>
              <div class="card">
                <div class="card-body">
                  <ul class="list-unstyled">
                    <li>
                      <div class="control-group opt-2">
                        <span v-tooltip.right="$t('contacts-component.send.message')" @click="goToSend('SmsSend')">
                          <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <span to="/whatsapp/send" v-tooltip.right="`${$t('generic-str.send')} WhatsApp`" @click="goToSend('WhatsAppSend')">
                          <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <span to="/email/send" v-tooltip.right="`${$t('generic-str.send')} ${$t('generic-str.email')}`" @click="goToSend('MailSend')">
                          <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                        </span>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <span to="/rcs/send" v-tooltip.right="`${$t('generic-str.send')} RCS`" @click="goToSend('RCSSend')">
                          <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-dropdown>
            <span>
              Enviar campanha
            </span>
          </div>
          <div class="extra send-campaign">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none p-0"
              no-caret
              right
              class="dropdown-menu-contact"
            >
              <template #button-content>
                <img src="assets/img/icons/svg/denylist.svg" class="c-pointer"/>
              </template>
              <div class="card">
                <div class="card-body">
                  <ul class="list-unstyled">
                    <li>
                      <div class="control-group opt-2">
                        <router-link to="/sms/denylist">
                          <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                        </router-link>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <router-link to="/whatsapp/denylist">
                          <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                        </router-link>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <router-link to="/email/denylist">
                          <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                        </router-link>
                      </div>
                    </li>
                    <li>
                      <div class="control-group opt-2">
                        <router-link to="/rcs/denylist">
                          <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-dropdown>
            <span>
              Denylist
            </span>
          </div>
          <div class="flex-grow-1"></div>
          <div class="create-contact">
            <a v-b-toggle.create-contact class="btn-add" v-if="tabLink == 'contacts'">
              <span>Criar contato</span> <span class="material-symbols-outlined" style="font-size: 18px !important;">add_circle </span>
            </a>
            <a v-b-toggle.group-sidebar class="btn-add" v-if="tabLink == 'groups'">
              <span>Criar grupo</span> <span class="material-symbols-outlined" style="font-size: 18px !important;">add_circle</span>
            </a>
          </div>
          <div class="extra hide">
            <button
              v-tooltip.top="$t('contact-list-component.group-contacts.create')"
              v-b-toggle.group-sidebar
              class="btn btn-light">
              <img src="assets/img/icons/svg/group_add.svg" class="c-pointer"/>
            </button>
          </div>
          <div class="extra" style="padding-top: 8px;margin-left: 15px;">
            <!-- <button class="btn btn-transparent btn-sm d-flex align-items-center" v-modal="{ target: 'create-custom-field-modal' }"><span class="material-symbols-outlined mr-1">edit_note</span></button> -->
            <span v-tooltip.top="$t('contact-list-component.custom-field.create')" role="button" class="material-symbols-outlined" v-b-toggle.create-custom-field-sidebar>edit_note</span>
          </div>
          <div class="extra">
            <router-link to="/contacts/import" v-tooltip.top="$tc('import-contacts-component.header', 1)">
              <img src="assets/img/icons/svg/file_upload.svg" class="c-pointer last"/>
            </router-link>
          </div>
          <div class="extra" style="padding-top: 8px;margin-left: 10px;">
            <!-- <button class="btn btn-transparent btn-sm d-flex align-items-center" v-modal="{ target: 'create-custom-field-modal' }"><span class="material-symbols-outlined mr-1">edit_note</span></button> -->
            <span role="button" class="material-symbols-outlined" v-b-toggle.sidebar-right-filter v-tooltip.top="$tc('contacts-component.filter', 1)">filter_list</span>
          </div>
        </div>
        <div class="loading min-h-300" v-if="fetched === false && tabLink == 'contacts'">
          <LoadingAnim />
        </div>
        <div class="contacts" v-else-if="contacts && tabLink == 'contacts'">
          <div class="row gx-2">
            <!-- <code class="col-12">{{selectedContacts}}</code> -->
            <div
              class="col-12 col-md-6 col-lg-4"
              v-for="(contact, index) in contacts"
              :key="index">
              <div class="card p-2 profile-item">
                <header class="d-flex justify-content-between">
                  <div>
                    <div class="control-group opt-2">
                      <label
                        class="control control-checkbox"
                        :for="'template-check' + index"
                      >
                        <input
                          v-model="selectedContacts"
                          type="checkbox"
                          class="custom-control-input"
                          :id="'template-check' + index"
                          :value="contact"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                  </div>
                  <div class="more">
                    <dropdown>
                      <template v-slot:text>
                        <span class="material-symbols-outlined title">
                          more_horiz
                        </span>
                      </template>
                      <template #items>
                        <!-- <a class="dropdown-item"
                          @click="showContactModal(contact, 'contact-modal')"
                        ><i class="fa fa-edit"></i> Editar modal</a> -->
                        <!-- <a class="dropdown-item" @click="remove(contact.id)"><i class="fa fa-trash"></i> Remover</a> -->
                        <a @click="showDetails(contact), editingContact = true" v-b-toggle.contact-sidebar-detail class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">edit</span>{{$t('app.edit')}}</a>
                        <a @click="remove(contact.id)" class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">delete_forever</span>{{$t('app.delete')}}</a>
                        <a @click="showDetails(contact)" v-b-toggle.contact-sidebar-detail class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">info</span>{{$t('app.more-details')}}</a>
                      </template>
                    </dropdown>
                  </div>
                </header>
                <button class="profile-data">
                  <div class="d-flex">
                    <div class="profile-photo">
                      <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }" v-if="contact.profile_picture == null">
                        <div class="fallback-image">
                          {{initials(contact.first_name, contact.last_name)}}
                        </div>
                      </figure>
                      <div class="img-preview" :class="{ hide: contact.profile_picture == null }" :style="`background: url(${contact.profile_picture})`"></div>
                    </div>
                    <aside class="profile-more-details card">
                      <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }" v-if="contact.profile_picture == null">
                        <div class="fallback-image">
                          {{initials(contact.first_name, contact.last_name)}}
                        </div>
                      </figure>
                      <div class="img-preview-large" :class="{ hide: contact.profile_picture == null }" :style="`background: url(${contact.profile_picture})`"></div>
                      <div class="p-3">
                        <div class="send-message">
                          <router-link to="/sms/send" class="nav-link">
                            {{$t('contacts-component.send.message')}}
                          </router-link>
                        </div>
                        <div class="py-2 text-center acts">
                          <!-- <code>{{contact}}</code> -->
                          <button @click="goToSend('SmsSend', contact)">
                            <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                          </button>
                          <button @click="goToSend('WhatsAppSend', contact)">
                            <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                          </button>
                          <button @click="goToSend('MailSend', contact)">
                            <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                          </button>
                          <button @click="goToSend('RCSSend', contact)">
                            <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                          </button>
                        </div>
                        <div class="row detail-info" v-if="contact.gender || contact.birthdate">
                          <div class="col-6" v-show="contact.gender">
                            <span class="material-icons-outlined">transgender</span>
                            {{$t(`contacts-component.gender.${contact.gender}`)}}
                          </div>
                          <div class="col-6" v-show="contact.birthdate">
                            <span class="material-icons-outlined">calendar_today</span>
                            {{momentDate(contact.birthdate)}}
                          </div>
                        </div>
                        <div class="row" v-show="contact.address">
                          <div class="col-12">
                            <span class="material-icons-outlined">place</span>
                            {{contact.address}}
                          </div>
                        </div>
                      </div>
                    </aside>
                    <div class="profile-meta flex-grow-1" @click="showDetails(contact)" v-b-toggle.contact-sidebar-detail>
                      <h3>
                        {{contact.first_name}}
                        {{contact.last_name}}
                      </h3>
                      <ul class="flex-grow-1">
                        <li v-if="contact.mobile_number || contact.phone">
                          <span class="material-icons-outlined">phone</span>
                          <template v-if="contact.mobile_number">
                            {{contact.mobile_number | maskPhone}}
                          </template>
                          <template v-if="contact.phone">
                            / {{contact.phone | maskPhone}}
                          </template>
                        </li>
                        <li v-if="contact.email">
                          <span class="material-icons-outlined">email</span>
                          {{contact.email}}
                        </li>
                        <li v-if="contact.website">
                          <span class="material-icons-outlined">language</span>
                          {{contact.website}}
                        </li>
                      </ul>
                      <pre class="sr-only">{{contact}}</pre>
                    </div>
                  </div>
                </button>
                <aside class="profile-socials" @click="visible = !visible">
                  <button v-if="contact.facebook">
                    <img src="@/assets/icons/social/facebook.png" />
                  </button>
                  <button v-if="contact.instagram">
                    <img src="@/assets/icons/social/instagram.png" />
                  </button>
                  <button v-if="contact.twitter">
                    <img src="@/assets/icons/social/twitter.png" />
                  </button>
                  <button v-if="contact.linkedin">
                    <img src="@/assets/icons/social/linkedin.png" />
                  </button>
                </aside>
              </div>
            </div>
            <div
              v-if="contacts.length == 0 && fetched == true"
              class="text-center table-placeholder"
            >
              <i class="far fa-edit font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
              <p>{{$t('warnings.register-user')}}</p>
            </div>
          </div>
          <pagination v-model="currentPage" :totalPages="pages" @change="fetch" />
        </div>
        <div class="contacts" v-else-if="groups && tabLink == 'groups'">
          <div v-if="!loadingGroups">
            <div v-for="(group, i) in groups" :key="group.id">
              <div class="content-row">
                <div class="col-12 d-flex">
                  <div class="control-group opt-2">
                    <label :for="'group-check' + i" class="control control-checkbox">
                      <input :id="'group-check' + i" :value="group" v-model="selectedGroups" type="checkbox">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                  <p class="text-item">{{ group.name }}</p>
                  <div class="option-wrapper thin-icon">
                    <a style="margin-top: 8px;" class="btn" v-modal="{ target: 'group-modal', data: group }"><span class="material-symbols-outlined"> edit </span></a>
                    <a style="margin-top: 8px;" class="btn" @click="removeGroup(group.id)"><span class="material-symbols-outlined color-red" style="color: #ff5c75;"> delete_forever </span></a>
                    <span class="span-contact">{{group.contacts_count}} Contatos</span>
                    <div class="text-right to-right right-slide-down">
                      <span class="material-symbols-outlined c-pointer" v-if="group.showContacts"
                        @click="closeGroup()">
                        keyboard_arrow_up
                      </span>
                      <span class="material-symbols-outlined c-pointer" v-if="!group.showContacts"
                        @click="openGroup(group), lastGroup = group">
                        keyboard_arrow_down
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <b-collapse v-model="group.showContacts">
                <div>
                  <div class="contacts" v-if="contactsGroup && !loadingGroup">
                    <div class="row gx-2">
                      <!-- <code class="col-12">{{selectedContacts}}</code> -->
                      <div
                        class="col-12 col-md-6 col-lg-4"
                        v-for="(contact, index) in contactsGroup"
                        :key="index">
                        <div class="card p-2 profile-item">
                          <header class="d-flex justify-content-between">
                            <div class="more">
                              <dropdown>
                                <template v-slot:text>
                                  <span class="material-symbols-outlined title">
                                    more_horiz
                                  </span>
                                </template>
                                <template #items>
                                  <!-- <a class="dropdown-item"
                                    @click="showContactModal(contact, 'contact-modal')"
                                  ><i class="fa fa-edit"></i> Editar modal</a> -->
                                  <!-- <a class="dropdown-item" @click="remove(contact.id)"><i class="fa fa-trash"></i> Remover</a> -->
                                  <a @click="showDetails(contact), editingContact = true" v-b-toggle.contact-sidebar-detail class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">edit</span>{{$t('app.edit')}}</a>
                                  <a @click="remove(contact.id)" class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">delete_forever</span>{{$t('app.delete')}}</a>
                                  <a @click="showDetails(contact)" v-b-toggle.contact-sidebar-detail class="dropdown-item d-flex align-items-center"><span class="material-symbols-outlined thin-icon">info</span>{{$t('app.more-details')}}</a>
                                </template>
                              </dropdown>
                            </div>
                          </header>
                          <button class="profile-data">
                            <div class="d-flex">
                              <div class="profile-photo">
                                <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }">
                                  <div class="fallback-image">
                                    {{initials(contact.first_name, contact.last_name)}}
                                  </div>
                                </figure>
                              </div>
                              <aside class="profile-more-details card">
                                <figure :style="{ backgroundColor: $strToColor(contact.first_name + '' + contact.last_name) }">
                                  <div class="fallback-image">
                                    {{initials(contact.first_name, contact.last_name)}}
                                  </div>
                                </figure>
                                <div class="p-3">
                                  <div class="send-message">
                                    <router-link to="/sms/send" class="nav-link">
                                      {{$t('contacts-component.send.message')}}
                                    </router-link>
                                  </div>
                                  <div class="py-2 text-center acts">
                                    <!-- <code>{{contact}}</code> -->
                                    <button @click="goToSend('SmsSend', contact)">
                                      <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                                    </button>
                                    <button @click="goToSend('WhatsAppSend', contact)">
                                      <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                                    </button>
                                    <button @click="goToSend('MailSend', contact)">
                                      <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                                    </button>
                                    <button @click="goToSend('RCSSend', contact)">
                                      <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                                    </button>
                                  </div>
                                  <div class="row detail-info" v-if="contact.gender || contact.birthdate">
                                    <div class="col-6" v-show="contact.gender">
                                      <span class="material-icons-outlined">transgender</span>
                                      {{$t(`contacts-component.gender.${contact.gender}`)}}
                                    </div>
                                    <div class="col-6" v-show="contact.birthdate">
                                      <span class="material-icons-outlined">calendar_today</span>
                                      {{momentDate(contact.birthdate)}}
                                    </div>
                                  </div>
                                  <div class="row" v-show="contact.address">
                                    <div class="col-12">
                                      <span class="material-icons-outlined">place</span>
                                      {{contact.address}}
                                    </div>
                                  </div>
                                </div>
                              </aside>
                              <div class="profile-meta flex-grow-1" @click="showDetails(contact)" v-b-toggle.contact-sidebar-detail>
                                <h3>
                                  {{contact.first_name}}
                                  {{contact.last_name}}
                                </h3>
                                <ul class="flex-grow-1">
                                  <li v-if="contact.mobile_number || contact.phone">
                                    <span class="material-icons-outlined">phone</span>
                                    <template v-if="contact.mobile_number">
                                      {{contact.mobile_number | maskPhone}}
                                    </template>
                                    <template v-if="contact.phone">
                                      / {{contact.phone | maskPhone}}
                                    </template>
                                  </li>
                                  <li v-if="contact.email">
                                    <span class="material-icons-outlined">email</span>
                                    {{contact.email}}
                                  </li>
                                  <li v-if="contact.website">
                                    <span class="material-icons-outlined">language</span>
                                    {{contact.website}}
                                  </li>
                                </ul>
                                <pre class="sr-only">{{contact}}</pre>
                              </div>
                            </div>
                          </button>
                          <aside class="profile-socials" @click="visible = !visible">
                            <button v-if="contact.facebook">
                              <img src="@/assets/icons/social/facebook.png" />
                            </button>
                            <button v-if="contact.instagram">
                              <img src="@/assets/icons/social/instagram.png" />
                            </button>
                            <button v-if="contact.twitter">
                              <img src="@/assets/icons/social/twitter.png" />
                            </button>
                            <button v-if="contact.linkedin">
                              <img src="@/assets/icons/social/linkedin.png" />
                            </button>
                          </aside>
                        </div>
                      </div>
                      <div
                        v-if="contactsGroup.length == 0"
                        class="text-center table-placeholder"
                      >
                        <i class="far fa-edit font-size-80"></i>
                        <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
                      </div>
                    </div>
                    <pagination v-model="currentPage" :totalPages="pages" @change="fetch" />
                  </div>
                  <div class="loading min-h-300" v-if="loadingGroup === true">
                    <LoadingAnim />
                  </div>
                </div>
              </b-collapse>
            </div>
            <pagination v-model="pageGroup" :totalPages="pagesGroup" @change="getGroups" />
          </div>
          <div class="responsive-table" v-else>
            <div class="text-center table-placeholder">
              <LoadingAnim />
            </div>
          </div>
        </div>
        <div class="responsive-table" v-else>
          <div class="text-center table-placeholder">
            <LottieAnimNoData />
            <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
            <p>{{$t('warnings.register-user')}}</p>
          </div>
        </div>
      </div>
      <!-- Show details (sidebar) -->
      <b-sidebar id="contact-sidebar-detail" ref="addTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
        <div class="col contact-info new-contacts-details" v-if="currentContact">
          <div class="expand-icon" @click="goDetail(currentContact)">
            <span class="material-icons-outlined">keyboard_double_arrow_left</span> Expandir
          </div>
          <button class="btn close" @click="$root.$emit('bv::toggle::collapse', 'contact-sidebar-detail'), currentContact = null">
            <span class="material-icons-outlined">close</span>
          </button>
          <div class="contents">
            <figure v-if="currentContact.profile_picture == null">
              <div class="fallback-image">
                {{initials(currentContact.first_name, currentContact.last_name)}}
              </div>
            </figure>
            <div class="img-preview" :class="{ hide: currentContact.profile_picture == null }" :style="`background: url(${currentContact.profile_picture})`"></div>
            <h4>
              {{currentContact.first_name}}
              {{currentContact.last_name}}
            </h4>
            <div class="channels">
              <ul class="list-unstyled">
                <li>
                  <div class="control-group opt-2">
                    <span v-tooltip.right="$t('contacts-component.send.message')" @click="goToSend('SmsSend')">
                      <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                    </span>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <span to="/whatsapp/send" v-tooltip.right="'Enviar WhatsApp'" @click="goToSend('WhatsAppSend')">
                      <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                    </span>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <span to="/email/send" v-tooltip.right="'Enviar Email'" @click="goToSend('MailSend')">
                      <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                    </span>
                  </div>
                </li>
                <li>
                  <div class="control-group opt-2">
                    <span to="/rcs/send" v-tooltip.right="'Enviar RCS'" @click="goToSend('RCSSend')">
                      <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="details">
              <scrollbar
                id="contentScroll"
                role="tablist"
                class="scrollbar-data"
                ref="scrollbarData">
                <!-- <pre>{{currentContact}}</pre> -->

                <template v-if="savingContact">
                  <div class="qt-block-ui relative"></div>
                </template>
                <template v-else-if="editingContact">
                  <h5 class="pb-0">{{$t('contact-list-component.register.infos')}}</h5>
                  <label class="md-3">
                    <span class="form-label">{{$tc('app.name', 1)}}*</span>
                    <input
                      class="form-control"
                      type="text"
                      :placeholder="$t('generic-str.lbl-first-name')"
                      v-model="formContact.first_name">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.lbl-last-name')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      :placeholder="$t('generic-str.lbl-last-name')"
                      v-model="formContact.last_name">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.email')}}*</span>
                    <input
                      class="form-control"
                      type="email"
                      placeholder="example@email.com"
                      v-model="formContact.email">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.lbl-cellphone')}}*</span>
                    <!-- <vue-tel-input
                      v-bind="bindProps"
                      v-model="formContact.mobile_number" /> -->
                      <tel-input ref="mobile_number" required v-model="formContact.mobile_number"/>
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$tc('generic-str.tel', 1)}}</span>
                    <!-- <vue-tel-input
                      v-bind="bindProps"
                      v-model="formContact.phone" /> -->
                      <tel-input ref="phone" v-model="formContact.phone"/>
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('properties.company')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: Yup Chat"
                      v-model="formContact.company">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.gender')}}</span>
                    <!-- <select class="form-control" aria-label="Default select example">
                      <option selected>Selecionar</option>
                      <option value="female">Feminino</option>
                      <option value="male">Masculino</option>
                      <option value="nonbin">Não binário</option>
                    </select> -->
                    <select-input
                      label="text"
                      :reduce="(gender) => gender.value"
                      v-model="formContact.gender"
                      :options="genders"
                      :clearable="false"
                    />
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.group')}}</span>
                    <select-input
                      multiple
                      label="name"
                      :reduce="(group) => group.id"
                      v-model="formContact.groups"
                      :options="groupList"
                      :clearable="false"
                    />
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.birthdate')}}</span>
                    <input
                      class="form-control"
                      placeholder=""
                      type="date"
                      v-model="formContact.birthdate">
                  </label>

                  <hr>
                  <!-- <code>Amount of customFields: {{customFields.length}}</code> -->
                  <div class="custom-field-wrapper" v-if="formContact.fill_custom_fields.length === 0">
                    <span class="d-flex align-items-center field-title"><span class="material-symbols-outlined mr-1">playlist_add</span> {{$t('contacts-component.custom-field')}}</span>
                    <!-- <button @click="handleShowAddCustomField" class="btn btn-add-card">{{$t('app.add-new')}} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button> -->
                    <button v-if="customFields.length === 0" v-b-toggle.create-custom-field-sidebar class="btn btn-add-card">{{$t('app.add-new')}} <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button>
                    <template v-else>
                      <div v-for="(customField, index) in customFields" :key="index" class="input-group">
                        <label class="form-label">{{customField.name}}{{customField.required_field ? '*' : ''}}</label>
                        <input
                          class="form-control w-100"
                          type="text"
                          @input="handleAddCustomField"
                          :required="customField.required_field"
                          :placeholder="customField.value ? customField.value : $t('contacts-component.custom-field')"
                          :ref="`customField-${index}`"
                          v-model="customField.value">
                      </div>
                    </template>
                    <template v-if="customFieldAdd">
                      <select-input
                        class="w-100"
                        label="name"
                        @input="handleSelectCustomField(selectedCustomFieldId)"
                        :reduce="(customField) => customField.id"
                        v-model="selectedCustomFieldId"
                        :options="customFields"
                        :selectable="(customField) => !checkCustomFieldId(customField.id)"
                        :clearable="false"
                      />
                      <input type="text" class="form-control mt-3" v-model="selectedCustomFieldNewValue" v-if="selectedCustomFieldId" :placeholder="$t('contact-list-component.custom-field.new')">
                      <!-- <select class="form-control" v-model="selectedCustomFieldId" @change="handleSelectCustomField(selectedCustomFieldId)" autocomplete="off">
                        <template v-for="(customField, index) in customFields">
                          <option :key="index" :value="customField.id" :name="customField.name" :disabled="checkCustomFieldId(customField.id)">{{customField.name}}</option>
                        </template>
                      </select> -->
                    </template>
                  </div>
                  <!-- <code>{{selectedCustomFieldId}}</code> -->
                  <!-- <template v-if="formContact.fill_custom_fields.length !== 0"> -->
                    <!-- <label v-for="(innerField, index) in formContact.fill_custom_fields" :key="index" class="md-3">
                      <span class="form-label">{{innerField.custom_field.name}}*</span>
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Campo customizado"
                        ref="customField"
                        v-model="innerField.value">
                    </label> -->
                    <!-- <select-input
                      class="w-100"
                      label="name"
                      :reduce="(option) => option.id"
                      v-model="editSelectedCustomField"
                      :options="currentCustomFields"
                      @input="handleEditSelectCustomField(editSelectedCustomField)"
                      :selectable="(customField) => !checkCustomFieldId(currentCustomFields.id)"
                      :clearable="false"
                      :editable="false"
                    />
                  </template> -->
                  <template v-else>
                    <h5 class="mt-3 pb-0">{{$t('contact-list-component.custom-field.edit')}}</h5>
                    <label v-for="(customField, index) in currentContact.fill_custom_fields" :key="index" class="md-3">
                      <!-- <code>{{customField.id}}</code> -->
                      <span class="form-label">{{customField.custom_field.name}}{{customField.custom_field.required_field ? '*' : ''}}</span>
                      <input
                        class="form-control"
                        type="text"
                        @input="handleEditSelectCustomField(customField.id, customField.value, true)"
                        :required="customField.custom_field.required_field"
                        :placeholder="customField.value ? customField.value : 'Campo customizado'"
                        :ref="`customField-${index}`"
                        v-model="customField.value">
                    </label>
                  </template>
                  <hr>
                  <h5 class="icon-title"><span class="material-symbols-outlined fill-icon">home</span>{{$t('app.address')}}</h5>
                  <label class="md-3">
                    <span class="form-label">CEP</span>
                    <input
                      v-on:keyup="checkZipCode(formContact.zip)"
                      class="form-control"
                      v-zip
                      type="text"
                      placeholder="77555-000"
                      pattern="\w{9}"
                      maxlength="9"
                      v-model="formContact.zip">
                  </label>
                  <label class="md-3">
                    <label class="form-label">{{$t('generic-str.state')}}</label>
                    <select v-model="formContact.state" type="text" class="form-control">
                      <option value="AC">Acre</option>
                      <option value="AL">Alagoas</option>
                      <option value="AP">Amapá</option>
                      <option value="AM">Amazonas</option>
                      <option value="BA">Bahia</option>
                      <option value="CE">Ceará</option>
                      <option value="DF">Distrito Federal</option>
                      <option value="ES">Espírito Santo</option>
                      <option value="GO">Goiás</option>
                      <option value="MA">Maranhão</option>
                      <option value="MT">Mato Grosso</option>
                      <option value="MS">Mato Grosso do Sul</option>
                      <option value="MG">Minas Gerais</option>
                      <option value="PA">Pará</option>
                      <option value="PB">Paraíba</option>
                      <option value="PR">Paraná</option>
                      <option value="PE">Pernambuco</option>
                      <option value="PI">Piauí</option>
                      <option value="RJ">Rio de Janeiro</option>
                      <option value="RN">Rio Grande do Norte</option>
                      <option value="RS">Rio Grande do Sul</option>
                      <option value="RO">Rondônia</option>
                      <option value="RR">Roraima</option>
                      <option value="SC">Santa Catarina</option>
                      <option value="SP">São Paulo</option>
                      <option value="SE">Sergipe</option>
                      <option value="TO">Tocantins</option>
                      <option value="EX">Estrangeiro</option>
                    </select>
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.city')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: Peruíbe"
                      v-model="formContact.city">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('app.address')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: Av Guimarães Rosa"
                      v-model="formContact.address">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.neighborhood')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: Av Guimarães Rosa"
                      v-model="formContact.district">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$tc('generic-str.lbl-number', 1)}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: 1000"
                      v-model="formContact.number">
                  </label>

                  <label class="md-3">
                    <span class="form-label">{{$t('generic-str.complement')}}</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Ex: apt 201"
                      v-model="formContact.complement">
                  </label>

                  <hr>

                  <h5><span class="material-symbols-outlined fill-icon">share</span>Social</h5>

                  <label class="md-3">
                    <span class="form-label">Website</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://"
                      v-model="formContact.website">
                  </label>

                  <label class="md-3">
                    <span class="form-label">Facebook</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://"
                      v-model="formContact.facebook">
                  </label>

                  <label class="md-3">
                    <span class="form-label">Instagram</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://"
                      v-model="formContact.instagram">
                  </label>

                  <label class="md-3">
                    <span class="form-label">Twitter</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://"
                      v-model="formContact.twitter">
                  </label>

                  <label class="md-3">
                    <span class="form-label">Linkedin</span>
                    <input
                      class="form-control"
                      type="text"
                      placeholder="https://"
                      v-model="formContact.linkedin">
                  </label>

                  <hr>

                  <h5>
                    <span class="material-symbols-outlined">description</span>
                    {{$t('contacts-component.description')}}
                  </h5>

                  <label class="md-3">
                    <span class="form-label">{{$t('app.description')}}</span>
                    <textarea class="form-control" v-model="formContact.description" :placeholder="$t('contacts-component.add-description')"></textarea>
                  </label>

                  <hr>

                  <div class="save d-flex justify-content-between">
                    <div></div>
                    <div class="d-flex justify-content-between">
                      <button class="btn btn-outline-primary" @click="editingContact=false, customFieldAdd=false">{{$t('app.cancel')}}</button>
                      <button id="saveBtn" class="btn btn-primary" :disabled="!isDifferent" @click="saveContact">{{$t('app.save')}}</button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="d-flex justify-content-between mb-3">
                    <h5 class="pb-0">{{$t('contact-list-component.register.infos')}}</h5>
                    <!-- <button class="edit" @click="showContactModal(currentContact, 'contact-modal')"> -->
                    <button class="edit mr-2" @click="editingContact = true">
                      <span class="material-symbols-outlined thin-icon">edit</span>
                    </button>
                  </div>

                  <ul>
                    <li v-if="currentContact.company">
                      <div class="icn">
                        <span class="material-icons-outlined">business</span>
                      </div>
                      <div class="item">
                        {{currentContact.company ? currentContact.company : $t('contact-list-component.user.job-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.gender">
                      <div class="icn">
                        <span class="material-icons-outlined">transgender</span>
                      </div>
                      <div class="item">
                      {{currentContact.gender ? currentContact.gender : $t('contact-list-component.user.gen-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.birthdate | formatDate">
                      <div class="icn">
                        <span class="material-icons-outlined">calendar_today</span>
                      </div>
                      <div class="item">
                      {{currentContact.birthdate | formatDate}}
                      </div>
                    </li>
                    <li v-if="currentContact.website">
                      <div class="icn">
                        <span class="material-icons-outlined">language</span>
                      </div>
                      <div class="item">
                      {{currentContact.website ? currentContact.website : $t('contact-list-component.user.website-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.email">
                      <div class="icn">
                        <span class="material-icons-outlined">email</span>
                      </div>
                      <div class="item">
                      {{currentContact.email ? currentContact.email : $t('contact-list-component.user.email-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.mobile_number">
                      <div class="icn">
                        <span class="material-icons-outlined">phone</span>
                      </div>
                      <div class="item" v-if="currentContact.mobile_number">
                        {{currentContact.mobile_number | maskPhone}}
                      </div>
                      <div class="item" v-else>
                        {{$t('contact-list-component.user.number-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.phone">
                      <div class="icn">
                        <span class="material-icons-outlined">phone</span>
                      </div>
                      <div class="item" v-if="currentContact.phone">
                        {{currentContact.phone | maskPhone}}
                      </div>
                      <div class="item" v-else>
                        {{$t('contact-list-component.user.number-not-found')}}
                      </div>
                    </li>
                    <li v-if="currentContact.facebook">
                      <div class="icn">
                        <figure>
                          <img src="@/assets/icons/social/facebook.png" />
                        </figure>
                      </div>
                      <div class="item">
                        <a :href="currentContact.facebook" target="_blank">@{{matchedString.facebook.replace(/[/]/g, '')}}</a>
                      </div>
                    </li>
                    <li v-if="currentContact.instagram">
                      <div class="icn">
                        <figure>
                          <img src="@/assets/icons/social/instagram.png" />
                        </figure>
                      </div>
                      <div class="item">
                        <a :href="currentContact.instagram" target="_blank">@{{matchedString.instagram.replace(/[/]/g, '')}}</a>
                      </div>
                    </li>
                    <li v-if="currentContact.twitter">
                      <div class="icn">
                        <figure>
                          <img src="@/assets/icons/social/twitter.png" />
                        </figure>
                      </div>
                      <div class="item">
                      <a :href="currentContact.twitter" target="_blank">@{{matchedString.twitter.replace(/[/]/g, '')}}</a>
                      </div>
                    </li>
                    <li v-if="currentContact.linkedin">
                      <div class="icn">
                        <figure>
                          <img src="@/assets/icons/social/linkedin.png" />
                        </figure>
                      </div>
                      <div class="item">
                        <a :href="currentContact.linkedin" target="_blank">@{{matchedString.linkedin.replace(/[/in/]/g, '')}}</a>
                      </div>
                    </li>
                    <li v-if="currentContact.address">
                      <div class="icn">
                        <span class="material-icons-outlined">location_on</span>
                      </div>
                      <div class="item" v-if="currentContact.address">
                        {{currentContact.address ? currentContact.address : ''}}{{currentContact.number ? `, nº ${currentContact.number}` : ''}}{{currentContact.complement ? `, ${currentContact.complement}` : ''}}{{currentContact.district ? `, ${currentContact.district}` : ''}}{{currentContact.zip ? `. ${$t('generic-str.zipcode')}: ${currentContact.zip}` : ''}}{{currentContact.state ? `, ${currentContact.state}` : ''}}
                      </div>
                    </li>
                    <li class="pb-0" v-if="currentContact.fill_custom_fields.length !== 0">{{$t('contacts-component.custom-field')}}</li>
                    <template v-if="currentContact.fill_custom_fields.length !== 0">
                      <li v-for="(innerField, index) in currentContact.fill_custom_fields" :key="index" :class="`custom-field field-${innerField.value.replace(/ /g, '-').toLowerCase()} pt-0`">
                        <div class="icn">
                          <span class="material-symbols-outlined">rate_review</span>
                        </div>
                        <div class="item">
                          <b>{{innerField.custom_field.name}}: </b> {{innerField.custom_field.sensitive_content === true ? `${innerField.value.substring(0,3)}...` : innerField.value}}
                        </div>
                      </li>
                    </template>
                  </ul>

                  <div class="humor">
                    <div class="d-flex note">
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 39.143C10.3793 39.143 0 33.1085 0 19.8672C0 6.62579 10.3793 0.591309 20 0.591309C25.3448 0.591309 30.2759 2.34993 33.931 5.55683C37.8966 9.07407 40 14.0396 40 19.8672C40 25.6948 37.8966 30.6258 33.931 34.143C30.2759 37.3499 25.3103 39.143 20 39.143Z" fill="url(#paint0_radial_439_8517)"/>
                        <path d="M36.5138 8.40869C38.3517 11.3742 39.3103 14.9052 39.3103 18.8328C39.3103 24.6604 37.2069 29.5915 33.2414 33.1087C29.5862 36.3156 24.6207 38.1087 19.3103 38.1087C13.0827 38.1087 6.55171 35.5742 2.69653 30.1983C6.40688 36.288 13.3862 39.1432 20 39.1432C25.3103 39.1432 30.2758 37.3501 33.931 34.1432C37.8965 30.6259 40 25.6949 40 19.8673C40 15.4742 38.8034 11.5708 36.5138 8.40869Z" fill="#EB8F00"/>
                        <path d="M20.2758 26.9015C19.0413 26.9015 17.8172 26.6946 16.6551 26.2808C15.2241 25.7877 13.6689 26.5498 13.1758 27.9773C12.8965 28.7842 13.0103 29.6739 13.4827 30.3842C14.9655 32.6601 17.3103 33.9015 20.2758 33.9015C23.2413 33.9015 25.5862 32.6601 27.0689 30.3842C27.9069 29.1256 27.562 27.4256 26.3034 26.5877C25.5931 26.1153 24.7034 26.0015 23.8965 26.2808C22.7344 26.6946 21.5103 26.9015 20.2758 26.9015Z" fill="#422B0D"/>
                        <path opacity="0.8" d="M9.86212 28.9364C13.1949 28.9364 15.8966 26.3891 15.8966 23.2468C15.8966 20.1045 13.1949 17.5571 9.86212 17.5571C6.52937 17.5571 3.82764 20.1045 3.82764 23.2468C3.82764 26.3891 6.52937 28.9364 9.86212 28.9364Z" fill="url(#paint1_radial_439_8517)"/>
                        <path opacity="0.8" d="M30.6896 28.9364C34.0224 28.9364 36.7241 26.3891 36.7241 23.2468C36.7241 20.1045 34.0224 17.5571 30.6896 17.5571C27.3569 17.5571 24.6552 20.1045 24.6552 23.2468C24.6552 26.3891 27.3569 28.9364 30.6896 28.9364Z" fill="url(#paint2_radial_439_8517)"/>
                        <path d="M35.1689 10.0294C32.3379 9.23284 30.2586 11.5466 30.2586 11.5466C30.2586 11.5466 30.0172 8.92249 27.5965 8.09836C24.693 7.1018 21.6275 8.68801 21.4448 12.8018C21.2379 17.488 27.4379 23.6397 27.4379 23.6397C27.4379 23.6397 35.1344 21.5708 37.6551 17.1846C39.7068 13.6156 37.3103 10.6294 35.1689 10.0294Z" fill="#F44336"/>
                        <path d="M35.1689 10.0292C34.8207 9.9361 34.462 9.89472 34.1034 9.90162C35.1172 10.5809 35.9207 11.5292 36.4207 12.6395C36.9069 13.8085 37.0345 15.3189 36.1103 17.1223C34.1069 21.043 27.9379 23.4706 27.4896 23.6395C28.0517 23.4809 35.2448 21.412 37.6689 17.1947C39.7069 13.6154 37.3103 10.6292 35.1689 10.0292Z" fill="#C62828"/>
                        <path d="M30.2552 11.9706C30.2345 11.612 30.1793 11.2534 30.0965 10.9016C29.8965 9.88784 29.269 9.00853 28.3724 8.48784C27.7586 8.12922 27.0793 7.90164 26.3759 7.81543C26.3759 7.81543 27.4103 8.12922 28.1483 9.56026C28.5241 10.312 28.7483 11.1327 28.8034 11.9706C28.8 12.2775 28.8552 12.5775 28.9655 12.8637C29.0896 13.1499 29.3931 13.3189 29.7034 13.2706C30.1759 13.1499 30.2793 12.5603 30.2552 11.9706Z" fill="#C62828"/>
                        <path d="M23.5517 10.3256C24.1966 9.52564 25.2552 8.76357 26.269 9.46702C26.8069 9.84288 26.8138 10.9084 26.1552 11.3877C25.0448 12.2015 24.9931 12.736 24.869 13.1843C24.7173 13.7187 24.6207 14.3567 24.1345 14.6256C23.6483 14.8946 23.2207 14.6256 22.9655 13.8567C22.531 12.6567 22.7517 11.3187 23.5517 10.3256Z" fill="#FF847A"/>
                        <path d="M12.1103 8.02937C9.31372 8.93627 8.86545 12.0156 8.86545 12.0156C8.86545 12.0156 7.21028 9.94661 4.72752 10.6156C1.76545 11.3949 0.0930344 14.4156 2.20683 17.9363C4.6551 21.9708 13.2206 23.6432 13.2206 23.6432C13.2206 23.6432 18.4792 17.657 18.1482 12.6087C17.8758 8.49834 14.2241 7.34317 12.1103 8.02937Z" fill="#F44336"/>
                        <path d="M12.1103 8.02939C11.7689 8.13974 11.4414 8.29836 11.1414 8.49836C12.362 8.50181 13.5552 8.84663 14.5896 9.49491C15.6448 10.1846 16.5862 11.3846 16.8207 13.4018C17.3276 17.7777 13.5345 23.2156 13.2414 23.6122C13.6241 23.1708 18.462 17.4604 18.1448 12.6053C17.8758 8.49836 14.2241 7.34318 12.1103 8.02939Z" fill="#C62828"/>
                        <path d="M9.10342 12.3776C8.88963 12.0845 8.64825 11.8155 8.37928 11.5741C7.65515 10.8362 6.6517 10.4431 5.62066 10.4983C4.91377 10.5397 4.22066 10.7224 3.58618 11.0431C3.58618 11.0431 4.62066 10.7293 6.02756 11.5155C6.76204 11.9397 7.39998 12.5086 7.91032 13.1845C8.07584 13.4431 8.28963 13.6638 8.54135 13.8397C8.80342 14.0086 9.14825 13.9776 9.37928 13.7672C9.68618 13.3948 9.44825 12.85 9.10342 12.3776Z" fill="#C62828"/>
                        <path d="M2.6207 14.7292C2.63449 14.4395 2.68621 14.1533 2.77242 13.8775C2.9138 13.2982 3.32414 12.8188 3.87242 12.5878C4.40001 12.4223 5.13104 12.5395 5.4138 13.0671C5.66552 13.5361 5.48276 14.1016 5.34483 14.6188C5.2207 15.212 5.15173 15.8188 5.13794 16.4257C5.14139 16.9223 4.74483 17.3257 4.24828 17.3326C3.77932 17.3326 3.4138 16.9257 3.15173 16.5361C2.78621 16.0051 2.60001 15.374 2.6207 14.7292Z" fill="#FF847A"/>
                        <defs>
                          <radialGradient id="paint0_radial_439_8517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20 19.8672) scale(19.6413)">
                            <stop offset="0.5" stop-color="#FDE030"/>
                            <stop offset="0.92" stop-color="#F7C02B"/>
                            <stop offset="1" stop-color="#F4A223"/>
                          </radialGradient>
                          <radialGradient id="paint1_radial_439_8517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.86212 20.6297) scale(6.57651 5.90544)">
                            <stop stop-color="#ED7770"/>
                            <stop offset="0.9" stop-color="#ED7770" stop-opacity="0"/>
                          </radialGradient>
                          <radialGradient id="paint2_radial_439_8517" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.5241 869.761) scale(6.71034)">
                            <stop stop-color="#ED7770"/>
                            <stop offset="0.9" stop-color="#ED7770" stop-opacity="0"/>
                          </radialGradient>
                        </defs>
                      </svg>
                      <div>9.5</div>
                    </div>
                    <p>Humor geral neste trimestre</p>
                  </div>

                  <div class="description">
                    <h5>{{$t('app.description')}}</h5>
                    <div :class="{ nodata: !currentContact.description }">
                      <template v-if="currentContact.description">
                        {currentContact.description}
                      </template>
                      <template v-else>
                        {{$t('contacts-component.no-description')}}
                      </template>
                    </div>
                  </div>

                  <div class="extra-info">
                    <h5>Informação complementar</h5>
                    <div class="item">
                      <div>
                        Ultima ação: Canal SMS
                      </div>
                      <div>
                        <span class="material-icons-outlined">sync</span>
                        22/06/2023 - 14:30
                      </div>
                    </div>
                    <div class="item">
                      <div>
                        Tipo da ação: Campanha
                      </div>
                    </div>
                    <div class="item">
                      <div>
                        Ação agendada: Canal WhatsApp
                      </div>
                      <div>
                        <span class="material-icons-outlined">sync</span>
                        22/06/2023 - 14:30
                      </div>
                    </div>
                  </div>
                </template>
              </scrollbar>
            </div>
          </div>
        </div>
      </b-sidebar>
      <!--/Show details (sidebar) -->
      <b-sidebar id="sidebar-right-filter" ref="showTaskSide" :title="$t('catalog.create')" no-header right backdrop shadow>
        <div class="col sidebar-header">
          <div class="header-info-wrapper">
            <p class="sidebar-title">{{$tc('app.filter', 2)}}</p>
            <p class="sidebar-desc">{{$t('contacts-component.filter_description')}}</p>
          </div>
          <span v-b-toggle.sidebar-right-filter class="material-symbols-outlined sidebar-close">close</span>
        </div>
        <div class="col title-column">
          <div class="form-wrapper">
            <form @submit.prevent="search" class="form-group col px-0 mb-0">
              <div class="input-group">
                <input
                  :placeholder="$t('generic-str.search-by.name')"
                  type="text"
                  class="form-control"
                  v-model="form.q"
                  v-on:keyup.enter="fetch(1)"
                />
                <div class="input-group-prepend">
                  <button @click="fetch(1)" type="button" class="btn icon_btn m-0" v-b-toggle.sidebar-right-filter>
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-sidebar>
    </div>

    <div class="page-content container-fluid d-none">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header hide">
              <form @submit.prevent="fetch(1)">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 to-left">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <router-link to="/contacts/import" class="btn btn-info">
                          <i class="icon dripicons-upload"></i
                          ><span class="hide-m">{{$t('generic-str.import')}}</span>
                        </router-link>
                        <button
                          type="button"
                          v-modal="{ target: 'create-contact' }"
                          class="btn btn-success emit-j-event"
                        >
                          <i class="icon dripicons-plus"></i
                          ><span class="hide-m">{{$t('generic-str.lbl-new-contact')}}</span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger btn-remove border-radius-right"
                          @click="deleteAll"
                          :class="{ disabled: selectedContacts.length == 0 }"
                        >
                          <i class="icon dripicons-trash color-white"></i
                          ><span class="hide-m">{{$t('generic-str.delete')}}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 input-group">
                    <input
                      type="text"
                      class="form-control to-left"
                      :placeholder="$t('generic-str.search-by.name')"
                      v-model="form.q"
                    />
                    <button
                      type="submit"
                      class="btn btn-danger to-right btn-search"
                    >
                      {{$t('generic-str.filter')}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-header">
              <div class="input-container to-right">
                <form @submit.prevent="fetch(1)" class="row" style="margin-right: 0;">
                  <input type="text" placeholder="Buscar" class="form-control" v-model="form.q">
                  <img src="assets/img/icons/icon_search.png" class="c-pointer" @click="fetch(1)"/>
                </form>
              </div>
              <div class="btns-right-container text-right opts_card">
                <span class="span-first" v-tooltip.top="'Adicionar pessoa'" v-modal="{ target: 'create-contact' }">
                  <img src="assets/img/icons/svg/person_add.svg" class="c-pointer"/>
                </span>
                <span v-tooltip.top="$t('contact-list-component.group-contacts.create')" v-modal="{ target: 'create-group-sidebar', data: {} }">
                  <img src="assets/img/icons/svg/group_add.svg" class="c-pointer"/>
                </span>
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none p-0"
                    no-caret
                    right
                    class="dropdown-menu-contact"
                  >
                    <template #button-content>
                      <img src="assets/img/icons/svg/campanha.svg" class="c-pointer"/>
                    </template>
                    <div class="card">
                      <div class="card-body">
                        <ul class="list-unstyled">
                          <li>
                            <div class="control-group opt-2">
                              <span v-tooltip.right="$t('contacts-component.send.message')" @click="goToSend('SmsSend')">
                                <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                              </span>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <span to="/whatsapp/send" v-tooltip.right="`${$t('generic-str.send')} WhatsApp`" @click="goToSend('WhatsAppSend')">
                                <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                              </span>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <span to="/email/send" v-tooltip.right="`${$t('generic-str.send')} ${$t('generic-str.email')}`" @click="goToSend('MailSend')">
                                <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                              </span>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <span to="/rcs/send" v-tooltip.right="`${$t('generic-str.send')} RCS`" @click="goToSend('RCSSend')">
                                <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dropdown>
                </span>
                <span v-tooltip.top="'Deny List'">
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none p-0"
                    no-caret
                    right
                    class="dropdown-menu-contact"
                  >
                    <template #button-content>
                      <img src="assets/img/icons/svg/denylist.svg" class="c-pointer"/>
                    </template>
                    <div class="card">
                      <div class="card-body">
                        <ul class="list-unstyled">
                          <li>
                            <div class="control-group opt-2">
                              <router-link to="/sms/denylist">
                                <img src="assets/img/icons/svg/SMS.svg" class="c-pointer"/>
                              </router-link>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <router-link to="/whatsapp/denylist">
                                <img src="assets/img/icons/svg/Whatsapp.svg" class="c-pointer"/>
                              </router-link>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <router-link to="/email/denylist">
                                <img src="assets/img/icons/svg/email.svg" class="c-pointer"/>
                              </router-link>
                            </div>
                          </li>
                          <li>
                            <div class="control-group opt-2">
                              <router-link to="/rcs/denylist">
                                <img src="assets/img/icons/svg/RCS.svg" class="c-pointer"/>
                              </router-link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </b-dropdown>
                </span>
                <span>
                  <router-link to="/contacts/import" v-tooltip.top="$tc('import-contacts-component.header', 1)">
                    <img src="assets/img/icons/svg/file_upload.svg" class="c-pointer"/>
                  </router-link>
                </span>
              </div>
            </div>
            <div class="card-body block-el p-0">
              <div class="tab-group-container-tags">
                <div class="tab-container-tags">
                  <div class="tab-container-tags-item" :class="{ 'tags-item-active': tabActive == 0 }" @click="resetTabs()">
                    <span>Todos</span>
                  </div>
                  <div v-for="(group, i) in groups" :key="i" class="tab-container-item-display">
                    <div class="tab-container-tags-item" :class="{ 'tags-item-active': checkItensTab(i) }" style="position: relative;">
                      <div class="container position-relative">
                        <div class="row">
                          <div class="col px-0">
                            <span class="material-symbols-outlined to-left stretched-link" @click="addRemoveItensTab(i)">group</span> <span> {{group.name}}</span>
                          </div>
                          <i class="fa fa-times ml-2 d-flex align-items-center" @click="removeGroup(group.id, group.name)" v-if="isGroudEdit"></i>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                  <div class="tab-container-tags-item-settings" @click="isGroudEdit = true" v-if="!isGroudEdit">
                    <span class="material-symbols-outlined to-left">settings</span> <span> &nbsp;&nbsp;Gerenciar grupos</span>
                  </div>
                  <div class="tab-container-tags-item-settings" @click="isGroudEdit = false" v-if="isGroudEdit">
                    <span class="material-symbols-outlined to-left">close</span> <span> &nbsp;&nbsp;{{$t('app.cancel')}}</span>
                  </div>
                  <div style="clear: both;"></div>
                </div>
              </div>
              <div class="all-check-box">
                <span class="to-left check-info">
                  <div class="control-group opt-2 to-left" style="margin-right: 5px;">
                    <label
                      class="control control-checkbox"
                      :for="'template-checka-all'"
                      v-if="contacts"
                    >
                      <input
                        v-model="selectAllContacts"
                        type="checkbox"
                        class="custom-control-input"
                        :id="'template-checka-all'"
                      />
                      <div class="control_indicator"></div>
                    </label>
                  </div> {{$t('generic-str.all')}}</span> <span class="to-left check-all-info c-pointer" @click="deleteAll"><span class="material-symbols-outlined to-left">delete_forever</span> <span class="to-left">Selecionados {{ selectedContacts.length }}</span></span>
                <div style="clear: both;"></div>
              </div>
              <div class="responsive-table">
                <div
                  v-if="!contacts && fetched"
                  class="text-center table-placeholder"
                >
                  <!-- <i class="far fa-edit font-size-80"></i> -->
                  <LottieAnimNoData />
                  <h5 class="card-title m-t-20">{{$t('warnings.no-users')}}</h5>
                  <p>{{$t('warnings.register-user')}}</p>
                </div>
                <div class="row" v-if="fetched">
                  <!-- <pre>{{contacts}}</pre> -->
                  <div v-for="(contact, i) in contacts" :key="i" class="col-sm-6">
                    <div class="card card-contacts relative" :id="i">
                      <div class="btn-edit-contact c-pointer">
                        <dropdown>
                          <template v-slot:text>
                            <span class="material-symbols-outlined title">
                              more_vert
                            </span>
                          </template>
                          <template #items>
                            <a class="dropdown-item"
                              @click="showContactModal(contact, 'contact-modal')"
                            ><i class="fa fa-edit"></i> Editar</a>
                            <a class="dropdown-item" @click="remove(contact.id)"><i class="fa fa-trash"></i> Remover</a>
                          </template>
                        </dropdown>
                      </div>
                      <div class="row card-contacts-list-item relative">
                        <div class="check-box-contact">
                          <div class="control-group opt-2">
                            <label
                              class="control control-checkbox"
                              :for="'template-check' + i"
                            >
                              <input
                                v-model="selectedContacts"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'template-check' + i"
                                :value="contact"
                              />
                              <div class="control_indicator"></div>
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-3">
                          <div class="card-contacts-list-avatar">
                            <span>{{ contact.first_name.substring(0, 1) }}</span>
                          </div>
                        </div>
                        <div class="col-sm-9 card-contacts-list-item-body">
                          <h3>{{ contact.first_name }} {{ contact.last_name }}</h3>
                          <h2 class="hide">{{ contact.company ? contact.company : '--' }}</h2>
                          <span style="display: none;">
                            ID da conta: 78905
                          </span>
                          <div class="card-contacts-list-item-body-info">
                            <div>
                              <span class="material-symbols-outlined to-left icon-contact">call</span> <span class="to-left">{{ contact.mobile_number ? contact.mobile_number : '--' | maskPhone }}</span>
                              <div style="clear: both;"></div>
                            </div>
                            <div v-if="contact.email">
                              <span class="material-symbols-outlined to-left icon-contact">mail</span> <span class="to-left">{{ contact.email ? contact.email : '' }}</span>
                              <div style="clear: both;"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12" style="margin-top: 10px;">
                          <div class="row">
                            <div class="col-sm-9">
                              <template>
                                <div>
                                  <b-button
                                    :class="contact.visible ? 'btn-collapse' : 'collapsed btn-collapse'"
                                    :aria-expanded="contact.visible  ? 'true' : 'false'"
                                    :aria-controls="`collapse-${i}`"
                                    @click="contact.visible = !contact.visible"
                                  >
                                    Informação complementar
                                  </b-button>
                                  <b-collapse :id="`collapse-${i}`" v-model="contact.visible" class="mt-2">
                                    <div class="list-item-info-collapse">
                                      <span>
                                        Genero: {{ contact.gender ? contact.gender : ' --' | formatGender }}&nbsp;&nbsp;&nbsp;&nbsp;,  Data de Nascimento: {{ contact.birthdate ? contact.birthdate : '--' | formatDate}}
                                      </span>
                                      <span>
                                        Endereço: {{ contact.city ? contact.city : ' --' }}, CEP: {{ contact.zip ? contact.zip : ' --' }}
                                      </span>
                                      <span>
                                        Ultima ação: {{ contact.last_action ? contact.last_action : ' --'}}
                                      </span>
                                      <span>
                                        Tipo da ação: {{contact.action_type ? contact.action_type : ' --'}}
                                      </span>
                                      <span>
                                        Ação agendada : {{contact.next_action ? contact.next_action: ' --'}}
                                      </span>

                                      <template v-if="contact.fill_custom_fields">
                                        <span :key="index" v-for="(fill, index) in contact.fill_custom_fields">
                                          {{fill.custom_field.name}}: {{fill.value}}
                                        </span>
                                      </template>
                                    </div>
                                  </b-collapse>
                                </div>
                              </template>

                            </div>
                            <div class="col-sm-3 text-right">
                              <span class="refresh-date-item" v-if="contact.last_action_date_time">
                                <img src="assets/img/icons/support_refresh.png" style="width: 15px; height: 20px;"/>
                                {{contact.last_action_date_time}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                /> -->
                <div class="loading min-h-300" v-if="fetched === false">
                  <LoadingAnim />
                </div>
                <br />
                <!-- <pagination :lastPage="pages" @change="fetch" /> -->
                <!-- <Pagination
                  v-model="currentPage"
                  :total-pages="totalPages"
                  @change="fetch()"
                ></Pagination> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-contact-sidebar
      id="create-contact"
      @sent="fetch(form.page)"
      :title="$t('contact-list-component.register.infos')"
    ></create-contact-sidebar>
    <contact-modal id="contact-modal" @sent="fetch(form.page)"></contact-modal>
    <group-sidebar id="group-sidebar" @sent="getGroups(pageGroup)"></group-sidebar>
    <group-modal id="group-modal" @sent="getGroups(pageGroup)"></group-modal>
    <create-custom-field-sidebar id="create-custom-field-sidebar" :title="$t('contact-list-component.custom-field.create')" :customFields="customFields" @created="fetchCustomFields()" />
    <!-- <create-custom-field-modal id="create-custom-field-modal" @created="fetchCustomFields()" /> -->
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
// import PageHeader from '@/components/PageHeader.vue';
import axios from '@/plugins/axios';
import PageHeader from '@/components/rebranding/PageHeader.vue';
import ContactService from '@/services/contact.service';
import CreateContactSidebar from '@/components/rebranding/contacts/CreateContactSidebar.vue';
import ContactModal from '@/components/rebranding/contacts/ContactModal.vue';
// import Pagination from '@/components/rebranding/Pagination.vue';
import Pagination from '@/components/PaginationComponent.vue';
import Swal from 'sweetalert2';
// import GroupModal from '@/components/rebranding/groups/CreateGroupModal.vue';
import GroupSidebar from '@/components/rebranding/groups/CreateGroupSidebar.vue';
import Dropdown from '@/components/Dropdown.vue';
import moment from 'moment-timezone';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import SelectInput from '@/components/SelectInput.vue';
import CreateCustomFieldSidebar from '@/components/rebranding/contacts/CreateCustomFieldSidebar.vue';
import Vue from 'vue';
import { isEqual, cloneDeep } from 'lodash';
import GroupModal from '@/components/rebranding/groups/GroupModal.vue';
// import CreateCustomFieldModal from '@/components/contacts/CreateCustomFieldModal.vue';

// import { VueTelInput } from 'vue-tel-input';
import TelInput from '@/components/form/TelInput.vue';
import { offset } from '@popperjs/core';

const webApi = axios();

export default {
  name: 'ListContact',
  components: {
    PageHeader,
    Pagination,
    CreateContactSidebar,
    ContactModal,
    GroupModal,
    GroupSidebar,
    Dropdown,
    LottieAnimNoData,
    LoadingAnim,
    // VueTelInput,
    TelInput,
    SelectInput,
    CreateCustomFieldSidebar,
    // CreateCustomFieldModal,
  },
  directives: {
    zip: {
      update(el) {
        // el.value = el.value.replace(/[-a-zA-Z]/g, ''); // Letters and capital letter, and special character: -
        if (!el.value) '';
        el.value = el.value.replace(/\D/g, '');
        el.value = el.value.replace(/(\d{5})(\d)/, '$1-$2');
      },
    },
  },
  data() {
    return {
      loadingGroups: true,
      tabLink: 'contacts',
      lastGroup: {},
      savingContact: false,
      editingContact: false,
      invalidPhone: false,
      loadingGroup: true,
      selectedGroups: [],
      pagesGroup: 0,
      pageGroup: 1,
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: true,
        },
        inputOptions: {
          showDialCode: false,
          // maxlength: 17,
        },
      },
      tabActive: 0,
      tabItens: [],
      genders: [
        {
          text: 'Feminino',
          value: 'female',
        },
        {
          text: 'Masculino',
          value: 'male',
        },
        {
          text: 'Prefiro não dizer',
          value: 'notsay',
        },
      ],
      groups: [],
      groupList: [],
      isGroudEdit: false,
      fetched: false,
      contacts: [],
      visible: false,
      form: {
        q: '',
        page: 1,
        name: '',
      },
      formGroup: {
        page: 1,
      },
      page: 1,
      pages: 1,
      currentPage: 1,
      lang: this.$i18n.locale,
      dateString: this.$i18n.locale === 'en' ? 'MMMM/DD/YYYY' : 'DD/MMMM/YYYY',
      totalPages: 1,
      selectedContacts: [],
      groupsSelected: [],
      currentCustomFields: [],
      currentContact: null,
      originalContact: null,
      formatDate: null,
      customFields: [],
      selectedCustomFieldId: '',
      selectedCustomFieldName: '',
      selectedCustomFieldNewValue: '',
      editSelectedCustomField: false,
      customFieldIdToEdit: {},
      editSelectedCustomFieldValue: '',
      existCustomField: false,
      customFieldAdd: false,
      hasBeenModified: false,
      isDifferent: false,
      totalWidth: null,
      maxWidth: null,
      matchedString: [
        {
          facebook: null,
          instagram: null,
          twitter: null,
          linkedin: null,
        },
      ],
    };
  },
  computed: {
    selectAllGroups: {
      get() {
        if (this.groups) {
          return this.selectedGroups.length === this.groups.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.groups.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedGroups = selected;
      },
    },
    selectAllContacts: {
      get() {
        if (this.contacts.length || this.contacts.length !== 0) {
          console.log('Contact val: ', this.contacts, 'event: ', this.contacts.length);
          return this.selectedContacts.length === this.contacts.length;
        }
        return false;
      },
      set(value) {
        console.log('Set value: ', value);
        const selected = [];

        if (value) {
          this.contacts.forEach((contact) => {
            selected.push(contact);
          });
        }

        this.selectedContacts = selected;
      },
    },
    // options: () => this.formContact,
  },
  created() {
    this.fetchCustomFields();
    this.fetch(1);
    this.getGroups(1);
    this.getGroupList();
  },
  filters: {
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }
      return phone;
    },
  },
  mounted() {
    setTimeout(() => this.carouselArrow(), 100);
    window.addEventListener('resize', () => {
      console.log('Resizing');
      this.getTagsWidth();
    });
    /* eslint-disable */
    /* const tabsBox = document.querySelector('.tabs-box');
    const arrowIcons = document.querySelectorAll('.icon .material-symbols-outlined');
    //const allTabs = document.querySelectorAll('.tab');
    let isDragging = false;

    this.handleIcons(tabsBox, arrowIcons);

    arrowIcons.forEach(icon => {
      icon.addEventListener('click', () => {
        console.log(icon.id);
        // if left icon clicked, reduce 350 from tabsBox scrollLeft, else add 350
        // console.log('Scroll teste, tabsBox:', (tabsBox.offsetWidth*0.40));
        tabsBox.scrollLeft += icon.id === 'left' ? -340 : 340;
        // tabsBox.scrollLeft += icon.id === 'left' ? -(tabsBox.offsetWidth) : (tabsBox.offsetWidth);
        setTimeout(() => this.handleIcons(tabsBox, arrowIcons), 50);
      });
    });

    const dragging = (e) => {
      // console.log('dragging');
      if (!isDragging) return;
      tabsBox.classList.add('dragging');
      tabsBox.scrollLeft -= e.movementX;
      this.handleIcons(tabsBox, arrowIcons);
    };

    const dragStop = () => {
      isDragging = false;
      tabsBox.classList.remove('dragging');
    }

    tabsBox.addEventListener('mousedown', () => isDragging = true);
    tabsBox.addEventListener('mousemove', dragging);
    document.addEventListener('mouseup', dragStop); */
    /* eslint-enable */
    this.$root.$on('getCustomFields', () => {
      this.fetchCustomFields();
    });
  },
  beforeDestroy() {
    // Destroying instance to avoid multiple future further calls
    this.$root.$off('getCustomFields');
  },
  /* watch() {
    this.groups.handleIcons();
  }, */
  watch: {
    currentContact: {
      handler(val, oldVal) {
        // console.log('Tst Item Changed: ', val, '\nOld value: ', oldVal);
        if (this.currentContact) {
          this.isDifferent = !isEqual(this.mapEmptyValueToNull(this.currentContact), this.mapEmptyValueToNull(this.originalContact));
        }
      },
      deep: true,
    },
    maxWidth: {
      handler(val, oldVal) {
        console.log('maxWidth watch: ', val, '\nOld value: ', oldVal);
      },
      deep: true,
    },
  },
  methods: {
    openGroup(group) {
      this.groups.forEach((element) => {
        element.showContacts = false;
      });
      group.showContacts = true;
      this.searchByGroup(group.id);
    },
    closeGroup() {
      this.groups.forEach((element) => {
        element.showContacts = false;
      });
      this.$forceUpdate();
    },
    goDetail(contact) {
      this.$router.push({ name: 'ContactDetail', params: contact });
    },
    deleteAllGroups() {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning-2'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const groupsToDelete = [];
          this.selectedGroups.forEach((group) => {
            groupsToDelete.push(group.id);
          });
          ContactService.deleteGroups({ ids: groupsToDelete }).then(
            () => {
              // this.hide();
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted-2'), 'success');
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    removeGroup(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text:
          this.$t('contact-list-component.groups.warning'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          ContactService.deleteGroup(id).then(
            () => {
              this.getGroups(this.pageGroup);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.groups.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    momentDate(date) {
      console.log('HELLO');
      let dateString = '';
      dateString = this.$i18n.locale === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
      return moment(date).locale(this.$i18n.locale).utc().format(dateString);
    },
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    checkZipCode(cep) {
      // cep = this.zipCodeMask(cep);
      setTimeout(() => {
        if (cep.length === 9) {
          webApi.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
            console.log('CEP Response: ', response);
            const street = response.data.logradouro;
            const neighbourhood = response.data.bairro;
            const city = response.data.localidade;
            const uf = response.data.uf;

            this.formContact.address = (neighbourhood !== '' && neighbourhood !== undefined) ? `${street}` : '';
            this.formContact.district = neighbourhood;
            this.formContact.city = city;
            this.formContact.state = uf;
          });
        }
      }, 200);
      // console.log('CEP Teste');
    },
    /* zipCodeMask(value) {
      if (!value) return '';
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{5})(\d)/, '$1-$2');
      return value;
    }, */
    handleEditSelectCustomField(id, value, state) {
      // console.log('Handling: ', e);
      // console.log('Founded: ', this.currentContact.fill_custom_fields.find((item) => item.custom_field_id === e).value);
      // console.log('Founded ID: ', this.currentContact.fill_custom_fields.find((item) => item.custom_field_id === e).id);
      // this.customFieldIdToEdit = this.currentContact.fill_custom_fields.find((item) => item.custom_field_id === e).id;
      // this.editSelectedCustomFieldValue = this.currentContact.fill_custom_fields.find((item) => item.custom_field_id === e).value;
      console.log('CF ID: ', id, '\nValue: ', value, '\nstate:', state);
      setTimeout(() => {
        if (this.isDifferent) {
          this.editSelectedCustomField = this.isDifferent;
          this.customFieldIdToEdit[id] = value;
          console.log('Current CF Obj: ', this.customFieldIdToEdit, '\nstate: ', this.editSelectedCustomField);
        } else {
          this.editSelectedCustomField = this.isDifferent;
        }
      }, 200);
    },
    handleEditCustomField() {
      if (this.editSelectedCustomFieldValue) {
        document.getElementById('saveBtn').removeAttribute('disabled');
      } else {
        document.getElementById('saveBtn').setAttribute('disabled', 'disabled');
      }
    },
    // Method needed because '' !=== null
    mapEmptyValueToNull(object) {
      Object.keys(object).forEach((key) => {
        if (object[key] === '') {
          object[key] = null;
        }
      });
      return object;
    },
    checkCustomFieldId(e) {
      /* eslint-disable */
      if (e) {
        return Object.values(this.currentContact.fill_custom_fields).some((el) => el.custom_field_id === e);
      } else {
        return Object.values(this.currentContact.fill_custom_fields).some((el) => el.custom_field_id === this.selectedCustomFieldId);
      }
      /* eslint-enable */
    },
    handleShowAddCustomField() {
      this.customFieldAdd = !this.customFieldAdd;
      if (this.customFieldAdd === false) {
        this.selectedCustomFieldId = '';
        this.selectedCustomFieldName = '';
        document.getElementById('saveBtn').setAttribute('disabled', 'disabled');
      }
    },
    handleAddCustomField() {
      /* eslint-disable */
      const custom_fields = {};
      /* eslint-enable */
      const cfs = this.customFields.map((s, index) => s);
      this.currentContact = { ...this.currentContact, ...{ custom_fields } };
      Object.keys(cfs).forEach((item) => {
        console.log(`CFS -> \nID: ${cfs[item].id}, \nVALUE: ${cfs[item].value}`);
        Vue.set(this.currentContact.custom_fields, cfs[item].id, cfs[item].value);
      });
    },
    handleSelectCustomField(e) {
      // Object.keys(customFields).find(key => customFields[key] === value);
      let result = '';
      result = this.customFields.find((item) => item.id === e);
      this.selectedCustomFieldName = result.name;
      // console.log('HandleChange: ', this.selectedCustomFieldName);
      this.existCustomField = this.checkCustomFieldId();
      document.getElementById('saveBtn').removeAttribute('disabled');
      // console.log('Custom field already exist: ', this.existCustomField);
    },
    handleIcons(tabsBox, arrowIcons) {
      /* eslint-disable */
      console.log('Scroll teste, Testevar: ', tabsBox.offsetWidth, arrowIcons);
      let scrollVal = Math.round(tabsBox.scrollLeft);
      let maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
      arrowIcons[0].parentElement.style.display = scrollVal <= 0 ? "none" : "flex";
      arrowIcons[1].parentElement.style.display = maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
      console.log('Scroll teste, maxScroll: ', maxScrollableWidth, '\nscroll value: ', scrollVal);
      /* eslint-enable */
    },
    isAddCustomField() {
      return new Promise((resolve, reject) => {
        webApi.post('/contacts/fill-custom-field/',
          {
            custom_field_id: this.selectedCustomFieldId,
            contact_id: this.currentContact.id,
            value: this.selectedCustomFieldNewValue,
          }).then(
          (response) => {
            // console.log('Custom field add: ', response);
            // this.$toast.show({
            //   title: this.$t('generic-str.success'),
            //   content: this.$t('contacts-component.update'),
            //   type: 'success',
            // });
            this.customFieldAdd = false;
            // this.savingContact = false;
            console.log('isAddCustomField: ', response);
            return resolve(response);
          },
          (error) => {
            // this.$toast.show({
            //   title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
            //     error.code
            //   }`,
            //   content: error.message,
            //   type: 'danger',
            // });
            // this.content = error;
            console.log('isAddCustomField: ', error);
            return reject(error);
          },
        );
      });
    },
    isCustomField() {
      // let customID = '';
      // let accID = '';
      // let contactID = '';
      return new Promise((resolve, reject) => {
        // accID = this.currentContact.fill_custom_fields[0].account_id;
        // contactID = this.currentContact.fill_custom_fields[0].contact_id;
        const currentCustomFields = this.currentContact.fill_custom_fields;
        // customID = this.customFieldIdToEdit;
        console.log('CF to edit: ', currentCustomFields);
        webApi.put('/contacts/fill-custom-field/items', {
          items: this.customFieldIdToEdit,
        }).then(
          (response) => {
            console.log('Contatos Custom field: ', response);
            // this.$toast.show({
            //   title: this.$t('generic-str.success'),
            //   content: this.$t('contacts-component.update'),
            //   type: 'success',
            // });
            // this.savingContact = false;
            return resolve(response);
          },
          (error) => {
            console.log('Contatos Custom field error: ', error);
            // this.$toast.show({
            //   title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
            //     error.code
            //   }`,
            //   content: error.message,
            //   type: 'danger',
            // });
            // this.content = error;
            return reject(error);
          },
        );
      });
    },
    isSaving() {
      return new Promise((resolve, reject) => {
        ContactService.editContact(this.formContact).then((response) => {
          console.log('isSaving response: ', response);
          // resolve('Response resolve:', response);
          // this.$toast.show({
          //   title: this.$t('generic-str.success'),
          //   content: this.$t('contacts-component.update'),
          //   type: 'success',
          // });
          // this.savingContact = false;
          return resolve(response);
        }).catch((error) => {
          // this.$toast.show({
          //   title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
          //     error.code
          //   }`,
          //   content: error.message,
          //   type: 'danger',
          // });
          // this.savingContact = false;
          console.log('isSaving error: ', error);
          return reject(error);
        });
      });
    },
    async saveContact() {
      this.editingContact = true;
      let myPromise = Promise;
      if ((this.customFieldAdd && !this.existCustomField) && this.isDifferent && this.editSelectedCustomField) {
        // console.log('TESTE: Ta salvando info, editando e add customField');
        myPromise = Promise.all([this.isAddCustomField(), this.isCustomField(), this.isSaving()]);
      } else if ((this.customFieldAdd && !this.existCustomField) && this.isDifferent) {
        // console.log('TESTE: Ta salvando info e add Custom Field');
        myPromise = Promise.all([this.isAddCustomField(), this.isSaving()]);
      } else if (this.editSelectedCustomField && this.isDifferent) {
        // console.log('TESTE: Está editando customField e salvando info');
        myPromise = Promise.all([this.isCustomField(), this.isSaving()]);
      } else if ((this.editSelectedCustomField && this.selectedCustomFieldId) && this.isDifferent) {
        // console.log('TESTE: Está editando customField existente e add customField novo');
        myPromise = Promise.all([this.isCustomField(), this.isAddCustomField()]);
      } else if (this.editSelectedCustomField && this.isDifferent) {
        // console.log('TESTE: Está editando customField');
        myPromise = Promise.all([this.isCustomField()]);
      } else if (this.selectedCustomFieldId) {
        // console.log('TESTE: Só está add customField');
        myPromise = Promise.all([this.isAddCustomField()]);
      } else if (this.isDifferent) {
        // console.log('TESTE: Ta salvando info');
        myPromise = Promise.all([this.isSaving()]);
      }
      myPromise.then((values) => {
        console.log('TESTE: Response values: ', values);
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: this.$t('contacts-component.update'),
          type: 'success',
        });
        this.editingContact = false;
        this.visible = false;
        this.savingContact = false;
        this.selectedCustomFieldId = '';
        this.selectedCustomFieldName = '';
        this.selectedCustomFieldNewValue = '';
        this.editSelectedCustomField = '';
        this.editSelectedCustomFieldValue = '';
        this.toggleSidebar('contact-sidebar-detail');
        this.fetch(this.form.page);
        // document.getElementById('contentScroll').scrollTo(0, 0);
      }).catch((error) => {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
            error.code
          }`,
          content: error.message,
          type: 'danger',
        });
        this.savingContact = false;
      });
    },
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
    /* saveContact() {
      this.editingContact = true;

      ContactService.editContact(this.formContact).then(() => {
        this.$toast.show({
          title: this.$t('generic-str.success'),
          content: this.$t('contacts-component.update'),
          type: 'success',
        });
        this.savingContact = false;
      }).catch((error) => {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
            error.code
          }`,
          content: error.message,
          type: 'danger',
        });
        this.savingContact = false;
      });
    }, */
    showDetails(item) {
      this.currentCustomFields = [];
      console.log('Show details: ', item);
      this.setCompareUser(item);
      this.editingContact = false;
      this.savingContact = false;
      this.currentContact = item;
      this.formContact = this.currentContact;
      // Fill current custom fields
      Object.keys(this.currentContact.fill_custom_fields).forEach((elm, i) => {
        // console.log('TTTT Inner fields: ', this.currentContact.fill_custom_fields[i].custom_field, '\nOuter field: ', this.currentContact.fill_custom_fields[i].value);
        this.currentCustomFields.push(this.currentContact.fill_custom_fields[i].custom_field);
      });
      /* eslint-disable */
      const re = /([-a-zA-Z0-9^\p{L}\p{C}\u00a1-\uffff@:%_\+.~#?&//=]{2,256}){1}(\.[a-z]{2,4}){1}(\:[0-9]*)?(\/[-a-zA-Z0-9\u00a1-\uffff\(\)@:%,_\+.~#?&//=]*)?([-a-zA-Z0-9\(\)@:%,_\+.~#?&//=]*)?/g;
      /* eslint-enable */
      if (item.facebook) {
        // this.matchedString = item.facebook;
        const str = item.facebook;
        let match = re.exec(str);
        while (match != null) {
          console.log(match[4]); // <====
          this.matchedString.facebook = match[4] ? match[4] : '';
          match = re.exec(str);
        }
      }
      if (item.instagram) {
        // this.matchedString = item.instagram;
        const str = item.instagram;
        let match = re.exec(str);
        while (match != null) {
          console.log(match[4]); // <====
          this.matchedString.instagram = match[4] ? match[4] : '';
          match = re.exec(str);
        }
      }
      if (item.twitter) {
        // this.matchedString = item.twitter;
        const str = item.twitter;
        let match = re.exec(str);
        while (match != null) {
          console.log(match[4]); // <====
          this.matchedString.twitter = match[4] ? match[4] : '';
          match = re.exec(str);
        }
      }
      if (item.linkedin) {
        // this.matchedString = item.linkedin;
        const str = item.linkedin;
        let match = re.exec(str);
        while (match != null) {
          console.log(match[4]); // <====
          this.matchedString.linkedin = match[4] ? match[4] : '';
          match = re.exec(str);
        }
      }
    },
    setCompareUser(user) {
      this.originalContact = cloneDeep(user);
      this.isDifferent = false;
    },
    showContactModal(item, id) {
      if (item.twitter) {
        item.twitter = item.twitter.replaceAll('https://twitter.com/', '');
      }
      if (item.instagram) {
        item.instagram = item.instagram.replaceAll('https://instagram.com/', '');
      }
      if (item.linkedin) {
        item.linkedin = item.linkedin.replaceAll('https://linkedin.com/', '');
      }
      if (item.facebook) {
        item.facebook = item.facebook.replaceAll('https://facebook.com/', '');
      }
      if (item.birthdate) {
        item.birthdate = moment(item.birthdate).format('DD/MM/YYYY');
      }
      this.$root.$emit('show.modal', id, item);
    },
    goToSend(name, contact) {
      if (this.tabLink === 'contacts') {
        if (contact) {
          this.selectedContacts.push(contact);
          this.$router.push({
            name,
            params: { contacts: this.selectedContacts },
          });
        } else {
          this.$router.push({
            name,
            params: { contacts: this.selectedContacts },
          });
        }
      } else {
        this.$router.push({
          name,
          params: { groups: this.selectedGroups },
        });
      }
    },
    resetTabs() {
      this.tabActive = 0;
      this.tabItens = [];
      this.groupsSelected = [];
      this.formGroup.groups = this.groupsSelected;
      this.$forceUpdate();
      // this.fetchGroup(1);
      this.fetch(1);
    },
    searchByGroup(id) {
      this.groupsSelected = [];
      this.groupsSelected.push(id);
      this.contactsGroup = [];
      this.loadingGroup = true;
      this.formGroup.groups = this.groupsSelected;
      this.fetch(1, true);
    },
    addRemoveItensTab(index) {
      console.log('Tab index: ', index);
      this.tabActive = 1;
      const contain = this.tabItens.indexOf(index);
      console.log('Contain var: ', contain);
      if (contain > -1) {
        console.log('Inner');
        this.tabItens.splice(contain, 1);
        this.groupsSelected.splice(contain, 1);
      } else {
        console.log('Out');
        this.tabItens.push(index);
        this.groupsSelected.push(this.groups[index].id);
      }
      this.$forceUpdate();
      this.formGroup.groups = this.groupsSelected;
      // this.fetchGroup(1);
      this.fetch(1);
    },
    checkItensTab(index) {
      const contain = this.tabItens.indexOf(index);
      if (contain > -1) {
        return true;
      }
      return false;
    },
    getTagsWidth() {
      const tabsMaxWidth = document.getElementById('tabs-box');
      const children = document.getElementById('tabs-box').children;
      this.maxWidth = parseInt(tabsMaxWidth.offsetWidth, 10);
      this.totalWidth = 0;
      for (let i = 0; i < children.length; i += 1) {
        this.totalWidth += parseInt(children[i].offsetWidth, 10);
      }
      console.log('Total children tabs-box width: ', this.totalWidth, '\nchildren length: ', children.length);
      this.totalWidth += ((children.length - 1) * 12); // 12 it is the gap value
    },
    getGroups(page = 1) {
      this.loadingGroups = true;
      ContactService.getGroups({
        page,
      }).then(
        (response) => {
          this.groups = response.data;
          console.log('Grupos');
          console.log(response.data);
          this.pagesGroup = response.last_page;
          this.loadingGroups = false;
          setTimeout(() => {
            this.getTagsWidth();
          }, 200);
          // setTimeout(() => this.handleIcons(tabsBox, arrowIcons), 100);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    getGroupList() {
      ContactService.getGroups().then(
        (response) => {
          this.groupList = response;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    carouselArrow() {
      /* eslint-disable */
      const tabsBox = document.querySelector('.tabs-box');
      // const allTabs = tabsBox.querySelectorAll(".tab");
      const arrowIcons = document.querySelectorAll('.icon .material-symbols-outlined');

      let isDragging = false;

      const handleIcons = (scrollVal) => {
          let maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
          arrowIcons[0].parentElement.style.display = scrollVal <= 0 ? "none" : "flex";
          arrowIcons[1].parentElement.style.display = maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
          console.log('Scroll teste, maxScroll: ', maxScrollableWidth, '\nscroll value: ', scrollVal);
      }

      arrowIcons.forEach(icon => {
          icon.addEventListener("click", () => {
              // if clicked icon is left, reduce 350 from tabsBox scrollLeft else add
              let scrollWidth = tabsBox.scrollLeft += icon.id === "left" ? -340 : 340;
              handleIcons(scrollWidth);
          });
      });

      /* allTabs.forEach(tab => {
          tab.addEventListener("click", () => {
              tabsBox.querySelector(".active").classList.remove("active");
              tab.classList.add("active");
          });
      }); */

      const dragging = (e) => {
          if(!isDragging) return;
          tabsBox.classList.add("dragging");
          tabsBox.scrollLeft -= e.movementX;
          handleIcons(tabsBox.scrollLeft)
      }

      const dragStop = () => {
          isDragging = false;
          tabsBox.classList.remove("dragging");
      }

      tabsBox.addEventListener("mousedown", () => isDragging = true);
      tabsBox.addEventListener("mousemove", dragging);
      document.addEventListener("mouseup", dragStop);
      /* eslint-enable */
    },
    searchContacts(page) {
      this.form.page = page;
      this.fetched = false;
      ContactService.searchContacts(this.form, this.form.name).then(
        (response) => {
          this.fetched = true;
          this.contacts = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page = 1, fetched = false) {
      this.currentPage = page;
      this.fetched = fetched;
      this.loadingGroup = true;
      this.contacts = [];

      let req;

      if (this.groupsSelected.length) {
        this.formGroup.page = page;
        req = ContactService.getByGroups(this.formGroup);
      } else {
        this.form.page = page;
        req = ContactService.getContacts(this.form);
      }

      req.then(
        (response) => {
          console.log('response.data', response.data);
          this.fetched = true;
          this.contactsGroup = response.data;
          this.loadingGroup = false;
          this.contacts = response.data;
          // this.contacts = JSON.parse(JSON.stringify(response.data).replace(/:null/gi, ':""')); // null to empty string (needed to isDifferent to work)
          this.pages = response.last_page;
          this.groupsSelected = [];
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetchGroup() {
      this.fetched = false;
      console.info('this.formGroup', this.fetched, this.formGroup);

      ContactService.getByGroups(this.formGroup).then(
        (response) => {
          console.info('this.formGroup', this.fetched, this.formGroup, response);
          this.fetched = true;
          this.contacts = response?.data || response;
          this.pages = response.last_page;
        }, (error) => {
          console.error('this.formGroup', this.fetched, this.formGroup);
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('domain.label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          const toDelete = [];
          toDelete.push(id);
          ContactService.deleteContacts({ ids: toDelete }).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                this.$t('contact-list-component.deleted'),
                'success',
              );
              // this.selectedContacts = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
          /* ContactService.deleteContact(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('contact-list-component.deleted'), 'success');
            },
            (error) => {
              this.content = error;
            },
          ); */
        }
      });
    },
    deleteAll() {
      if (!this.selectedContacts.length) return;

      Swal.fire({
        title: this.$t('contact-list-component.remove'),
        text: this.$t('contact-list-component.remove-label'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedContacts.forEach((item) => {
            toDelete.push(item.id);
          });
          ContactService.deleteContacts({ ids: toDelete }).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                this.$t('contact-list-component.deleted-2'),
                'success',
              );
              this.selectedContacts = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    initials(firstname, lastname) {
      (firstname === null ? firstname = '' : firstname); // Avoiding displaying 'null' string
      (lastname === null ? lastname = '' : lastname); // Avoiding displaying 'null' string
      const emojiRegex = /\p{Emoji}/u;
      // console.log('Firstname: ', firstname, '\nLastname: ', lastname);
      let fullname = '';
      if (firstname) {
        firstname = !firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      if (lastname) {
        lastname = !lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0).toUpperCase();
      }
      fullname = firstname + lastname;
      /* if (firstname && lastname) {
        console.log('Firstname: ', firstname, '\nLastname: ', lastname, '\nMatch: ', lastname.match(emojiRegex));
        fullname = (!firstname.match(emojiRegex) ? firstname.charAt(0).toUpperCase() : firstname.replace(emojiRegex, '').charAt(0)) + (!lastname.match(emojiRegex) ? lastname.charAt(0).toUpperCase() : lastname.replace(emojiRegex, '').charAt(0));
      } */
      // const fullname = firstname.charAt(0) + lastname;
      // fullname = fullname.split(/(?<!\pL|\pN)\pL+(?!\pL|\pN)/g);

      /* if (firstname) {
        firstname = firstname.substring(0, 1).toUpperCase();
      } else {
        firstname = '-';
      }

      if (lastname) {
        lastname = lastname.substring(0, 1).toUpperCase();
      } else if (!firstname) {
        lastname = '';
      } */

      return fullname;
    },
    fetchCustomFields() {
      axios().get('/contacts/custom-field').then((response) => {
        this.customFields = [];

        const customFields = this.customFields;

        response.data?.forEach((field) => {
          if (field.id && field.name) {
            customFields.push({
              account_id: field.account_id,
              appears_omni: field.appears_omni,
              deleted: field.deleted,
              description: field.description,
              id: field.id,
              name: field.name,
              required_field: field.required_field,
              sensitive_content: field.sensitive_content,
            });
          }
        });
        // this.selectedCustomFieldId = customFields[0].name;
        console.log('CUSTOM FIELD', customFields, response);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.fallback-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  color: #fff;
}
.contact-info {
  height: 100%;
  .channels {
    margin-bottom: 2rem;
    ul {
      display: flex;
      gap: 2.5rem;
      justify-content: center;
    }
  }
}
.new-contacts-details {
  // position: fixed;
  // z-index: 110;
  // right: 0;
  // top: 0;
  // max-width: 530px;
  // width: 100%;
  // height: 100vh;

  // & > .backdrop {
  //   background: rgba(0, 0, 0, .5);
  //   position: fixed;
  //   z-index: -1;
  //   left: 0;
  //   top: 0;
  //   height: 100%;
  //   width: 100%;
  // }

  .close {
    font-size: 1.5rem;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  & > .card {
    height: 100%;
  }

  .contents {
    // padding: 40px;
    // padding-left: 2rem;
    // padding-right: 2rem;
    padding-top: 2rem;
    height: 100%;

    figure {
      background: #ccc;
      border-radius: 48px;
      height: 84px;
      width: 84px;
      margin: 0 auto;
    }

    h4 {
      margin: 1.2rem 0;
      text-transform: uppercase;
      text-align: center;
      font-size: .72rem;
    }

    .humor {
      border-bottom: thin solid #dfdfdf;
      text-align: center;
      padding: 20px 0;

      .note {
        margin: 0 auto;
        width: 120px;

        div {
          padding-left: .8rem;
          font-size: 2.5rem;
          font-weight: 500;
          line-height: 1;
        }
      }

      p {
        padding-top: 20px;
      }
    }

    h5 {
      padding-bottom: 24px;
      margin: 0;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }

    .extra-info {
      & > .item {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        display: inline-flex;
        align-items: center;
        gap: 46px;
      }
    }

    .description {
      padding: 24px 0;

      div {
        min-height: 100px;

        &.nodata {
          color: #dfdfdf;
        }
      }
    }

    .details {
      height: calc(100% - 155px);

      .save .d-flex {
        gap: 40px;
      }

      /* input:valid {
        background-color: #6EDA2C1A;
      } */

      /* input:invalid {
        background-color: #F6293414;
      } */

      label {
        padding: 12px 0;
        display: block;

        span {
          display: block;
          margin-bottom: 10px;
        }
      }

      .scrollbar-data {
        position: relative;
        overflow: hidden;
        height: 100%;
      }

      h5 {
        font-size: 1rem;
        font-weight: normal;
        display: flex;
        align-items: center;
        color: #595959;

        .material-symbols-outlined,
        .fill-icon {
          margin-right: .25rem;
          color: var(--gray-font-color-2);
        }
      }

      ul {
        min-height: 65%;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin: 0;
          padding: .62rem 0;

          &::after {
            clear: both;
            content: "";
            height: 1px;
            display: block;
          }

          .icn {
            float: left;
            width: 32px;
          }

          .material-icons-outlined, .material-symbols-outlined {
            color: #752de6;
            font-size: 1.4rem;
            vertical-align: middle;
            margin-right: .5rem;
          }

          figure {
            display: inline-block;
            height: 24px;
            width: 24px;
            border-radius: 12px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.new-group-contacts {
  position: relative;
  padding: 80px 0;
}

@media (max-width: 767.98px) {
  .new-group-contacts {
    padding: 0;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.new-contacts-search {
  margin: 2rem 0 4rem 0;

  .text-field {
    position: relative;
    margin: 0 auto;
    max-width: 952px;
    background: #fff;
    border: thin solid #ccc;
    border-radius: .2rem;
    width: 100%;

    input {
      background: transparent;
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
      height: 48px;
      border: none;
    }

    .material-icons-outlined {
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 2rem;
      pointer-events: none;
    }
  }
}
.new-contacts {
  .card {
    box-shadow: none !important;
    border: thin solid #dfdfdf;
    border-radius: .2rem;
  }

  .all-actions {
    padding-bottom: 4rem;

    .extra {
      margin-left: 5px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }

      button {
        padding: 0 !important;
      }

      img {
        max-width: 25px;
        width: 28px;

        &.last {
          width: 20px;
        }
      }
    }
  }

  .action {
    padding: .5rem;
    padding-left: 0;

    *, button {
      font-weight: normal !important;
      color: #697077 !important;
    }

    button {
      background: transparent;
      border: none;
    }

    .control-group, .material-symbols-outlined {
      margin-right: .5rem;
    }
  }

  header {
    height: 2.0rem;
  }

  .profile-item {
    padding-bottom: 2rem !important;
    cursor: pointer;
    position: relative;
    z-index: 0;

    &:hover {
      z-index: 10 !important;

      & ~ .profile-item {
        z-index: 1 !important;
      }

      .profile-socials button {
        margin-left: 5px;
      }
    }
  }

  .profile-data {
    border: none;
    background: transparent;
    text-align: left;
    position: relative;
    z-index: 10;
    &:focus {
      outline: unset !important;
    }
  }
  .profile-more-details * {
    transition: .5s ease-in-out;
  }
  .profile-more-details {
    position: absolute;
    z-index: -100;
    width: 250px;
    height: 94px;
    color: #5a5a5a;
    opacity: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10) !important;

    .detail-info {
      .col-6 {
        display: flex;
        align-items: center;
        gap: .25rem;
      }
    }

    .send-message {
      text-align: center;
      font-size: .92rem;

      &, ::v-deep a {
        color: #000;
      }
    }

    figure {
      background: #752de6;
      // height: 268px;
      height: 0;
    }

    figure ~ div, figure ~ div * {
      height: 0;
    }

    .acts {
      button {
        border: none;
        background: transparent;
        padding: 5px;
        margin: 0 10px;
        font-size: 1.5rem;

        img {
          width: 20px;
        }
      }
    }
  }

  .profile-photo {
    width: 115px;
    margin: 0 auto;

    figure {
      margin: 0 auto;
      border-radius: 48px;
      background: #752de6;
      width: 92px;
      height: 92px;
    }

    &:hover {
      color: #fc0;

      & ~ .profile-more-details {
        opacity: 1;
        z-index: 100;
      }
    }
  }

  .profile-more-details:hover {
    opacity: 1;
    z-index: 100;
    height: auto;
  }

  .profile-more-details:hover figure {
    height: 200px;
  }

  .profile-more-details:hover figure ~ div, .profile-more-details:hover figure ~ div * {
    height: auto;
    z-index: 200;
  }

  .profile-meta {
    position: relative;

    h3 {
      color: #752de6;
      font-size: .82rem;
      text-transform: uppercase;
    }

    ul {
      list-style-type: none;

      &, li {
        margin: 0;
        padding: 0;
      }

      li {
        padding: .12rem 0;
        color: #697077;

        span {
          font-size: 1.32rem;
          font-weight: 100;
          vertical-align: middle;
        }
      }
    }
  }

  .profile-socials {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    right: 1rem;

    button {
      position: relative;
      z-index: 10;
      box-shadow: 0 0 0 0.2rem #fff;
      // background: #fc0;
      border-radius: 13px;
      height: 26px;
      width: 26px;
      border: none;
      margin-left: -5px;
      font-size: 0;
      overflow: hidden;
      transition: .2s ease-out;

      &:focus {
        outline: unset !important;
      }

      img {
        transform: scale(1.5);
      }
    }

    button + button { z-index: 9; }
    button + button + button { z-index: 8; }
    button + button + button + button { z-index: 7; }
    button + button + button + button + button { z-index: 6; }
    button + button + button + button + button + button { z-index: 5; }
  }

  .more {
    button {
      background: transparent;
      border: none;
    }

    a {
      color: var(--gray-font-color-4);
    }

    span {
      color: var(--gray-font-color-4);
      font-size: 3rem;
      line-height: 0.5;
      &.material-symbols-outlined.thin-icon {
        font-size: 1.5rem;
        margin-right: .5rem;
      }
    }
  }
}

.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  margin-left: 8px;
}
.card-contacts {
  margin: 18px;
}
.card-contacts-list-item {
  padding: 20px;
}
.card-contacts-list-avatar {
  background: #C02FDF;
  width: 65px;
  height: 65px;
  border-radius: 55px;
  text-align: center;
  vertical-align:middle;
  margin: 0 auto;
}
.card-contacts-list-avatar span {
  font-size: 25px;
  color: white;
  display: block;
  padding-top: 15px;
}
.card-contacts-list-item h3 {
  color: #752DE6;
  font-size: 14px;
}
.card-contacts-list-item h2 {
  color: #9CA7B0;
  font-size: 14px;
}
.card-contacts-list-item-body span{
  color: #9CA7B0;
  font-size: 13px;
  display: block;
  margin-top: 5px;
  font-weight: 300;
}
.card-contacts-list-item-body-info {
  font-size: 13px;
  color: #9CA7B0;
  margin-top: 10px;
}
.btn-collapse {
  background: none !important;
  padding: 0px !important;
  border: none !important;
  color: #9CA7B0 !important;
  font-weight: 500 !important;
  height: 0 !important;
}
.btn-collapse:hover{
  background: none !important;
  padding: 0px !important;
  border: none !important;
  color: #8e9397 !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}
.refresh-date-item {
  color: #9CA7B0;
  font-size: 10px;
}
.list-item-info-collapse {
  color: #9CA7B0;
  font-size: 14px;
  margin-top: 20px;
}
.list-item-info-collapse span {
  display: block;
}
.btn-edit-contact {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  z-index: 2;
}
.btn-edit-contact img {
  cursor: pointer !important;
}
.btn-edit-contact .dropdown {
  cursor: pointer !important;
}
.input-container {
  float: right;
  position: relative;
  width: 40%;
  margin-left: 10px;
}
.input-container img {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  margin-top: 10px;
  margin-right: 8px;
  cursor: pointer;
}
.to-right {
  float: right !important;
}
.btns-right-container {
  width: 55%;
  float: right;
  margin-top: 10px;
}
.btns-right-container span {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-left: 10px;
}
.btns-right-container a {
  width: 50px;
  height: 50px;
  padding: 10px;
}
.btns-right-container a:hover {
  background: rgb(238, 238, 238);
  border-radius: 10px;
  cursor: pointer;
}
.btns-right-container span:hover {
  background: rgb(238, 238, 238);
  border-radius: 10px;
  cursor: pointer;
}
.btns-right-container .span-first {
  background: #752DE6;
  border-radius: 10px;
}
.to-left {
  float: left !important;
}
.icon-contact {
  font-size: 18px !important;
  margin-right: 5px !important;
  margin-top: 6px !important;
}
.tab-container-tags-item i{
  color: #752DE6;
}
.tab-container-tags-item {
  float: left;
  border: 1px solid transparent;
  color: #752DE6;
  font-size: 16px;
  /* padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px; */
  // border-radius: 20px;
  // margin-right: 10px;
  cursor: pointer;
}
.tab-container-tags-item-settings i{
  color: #D5273E;
}
.tab-container-tags-item-settings {
  float: left;
  color: #D5273E;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  ::v-deep {
    .all-actions {
      flex-wrap: wrap;
    }
    .action ~ .flex-grow-1 {
      width: 100%;
    }
    .create-contact {
      /* width: 100%; */
      order: 2;
      margin-top: 10px;
    }
  }
  .text-wrapper {
    display: flex;
    align-items: center;
  }
  .tab-container-tags-item-settings {
    position: absolute;
    top: 0;
    right: 0;
  }
  .tab-container-item-display {
    flex: 1 0 45%;
    margin: auto;

    /* .tab-container-tags-item {
      padding: 0;
    } */
  }
  .tags-item-active {
    margin-left: unset;
  }
  .tab-container-tags-item {
    margin-right: unset;
    padding: 5px;
    /* & > div {
      padding: 0;
    } */
    .material-symbols-outlined {
      margin-right: 10px;
    }
  }
}
.tags-item-active {
  span {
    background: #752DE6;
    color: #fff;
  }
}
.tags-item-active i{
  color: #fff;
}
.tab-group-container-tags {
  margin-left: 20px;
  margin-bottom: 40px;
}
.dropdown-menu-contact ul{
  min-width: 50px !important;
  width: 50px !important;
}
.rebranding .card .opts_card {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 25px;
}
.btns-right-container {
  margin: 0px;
  max-width: 500px;
  text-align: right !important;
  display: block !important;
}
.tab-container-item-display {
  // min-width: 110px;
  float: left;
}
.tab-container-tags-item{
  transition: margin-left 0.2s;
}
.tags-item-active {
  margin-left: 3px;
}
.check-box-contact{
  position: absolute;
  top: 10px;
  left: 30px;
  z-index: 1;
}
.all-check-box {
  margin-left: 20px;
}
.check-info {
  margin-right: 20px;
  display: block;
}
.check-all-info {
  color: #9CA7B0;
}
.c-pointer {
  cursor: pointer !important;
}
.dropdown-menu-right{
  top: 40px !important;
}
@media (max-width: 767.98px) {
  ::v-deep .tab-container-tags {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
}
.btn-tags {
  border: 1px solid var(--gray-font-color-3);
  border-radius: 25px;
  font-weight: 400;
  color: var(--gray-font-color-2);
}

/* Tags wrapper */
.tags-wrapper {
  position: relative;
  padding: .5rem 10px .5rem 35px;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  background-color: var(--background);
  border-radius: 13px;
  margin-left: -30px;
  .icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;

    .material-symbols-outlined {
      width: 40px;
      height: 40px;
      cursor: pointer;
      font-size: 1.2rem;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      background-color: var(--background);
      border: 1px solid var(--gray-font-color-3);

      &:hover {
        background: #efedfb;
      }
    }

    &:first-child {
      left: 0;
      display: none;
      background: linear-gradient(90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-left: 30px;
      }
    }
    &:last-child {
      right: 0;
      // display: none;
      justify-content: flex-end;
      background: linear-gradient(-90deg, var(--background) 70%, transparent);

      & .material-symbols-outlined {
        margin-right: 15px;
      }
    }
  }
  .tabs-box {
    display: flex;
    gap: 12px;
    list-style: none;
    margin-bottom: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-left: 0;

    /* .tab:not(.tab:last-child) {
      margin-right: 12px;
    } */

    &.dragging {
      scroll-behavior: auto;
      cursor: grab;

      & .tab {
        user-select: none;
        pointer-events: none;
      }
    }
  }
  .tab {
    cursor: pointer;
    font-size: 1.10rem;
    white-space: nowrap;
    background: var(--background);
    border-radius: 30px;
    border: 1px solid var(--gray-font-color-3);
    padding: 5px 12px;

    &.active {
      background-color: var(--clr-yup-purple);
      color: #FFF;
      border-color: transparent;
    }
  }
}
.opt-tags {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .btn-settings {
    width: fit-content;
    cursor: pointer;
    font-size: 1.10rem;
    white-space: nowrap;
    background: var(--background);
    // border-radius: 30px;
    // border: 1px solid var(--gray-font-color-3);
    padding: 5px 12px;
    margin-left: auto;

    a {
      display: flex;
      color: var(--gray-font-color-4);
    }
  }
}
::v-deep {
  .btn {
    border-radius: 8px;
  }
  ul.pagination {
    padding-right: 3rem !important;
  }
  .custom-field-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .field-title {
      color: var(--foreground);
      font-weight: 400;
    }
    button {
      margin-left: auto;
    }
  }
  .btn-add-card:not(.col-task-grid .btn-add-card) {
    color: var(--clr-yup-purple);
    // text-decoration: underline;
    font-size: 1rem;
    font-weight: 400;
  }
  .btn-add-col, .col-task-grid .btn-add-card {
    background-color: #6b23dc14;
    border-color: #e5e5e5;
    color: var(--clr-yup-purple);
    font-weight: 400;
    border-radius: 8px;

    &:hover,
    &:focus {
      background-color: #6b23dc5e;
      color: #772ced;
    }
  }
}
/* Sidebar add prod */
::v-deep .b-sidebar {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  .edit {
    background: transparent;
    border: none;
    padding: .1rem;
    font-size: 1.3rem;
    color: var(--gray-font-color-2);
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: .5rem;
    margin-bottom: 2rem;
    .sidebar-title {
      color: var(--foreground);
      font-size: 1.25rem;
    }
    .sidebar-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    p {
      display: block;
      font-size: 1rem;
      margin-bottom: 1px;
    }
    p, span.material-symbols-outlined {
      color: var(--gray-font-color-2);
    }
  }
  .title-column {
    margin-bottom: 2rem;
    .title-task {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
      * {
        font-size: 1.625rem;
      }
      .not-editing {
        margin-right: 1rem;
      }
    }
  }
  .responsable-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      margin-right: .5rem;
      margin-bottom: 0;
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:last-of-type {
        margin-right: .5rem;
      }
    }
  }
  .description-column {
    > p {
      font-size: 1rem;
      margin-bottom: .5rem;
    }
    & p:last-of-type {
      font-size: .875rem;
      color: var(--gray-font-color);
    }
    /* textarea {
      margin-bottom: 1px;
    } */
  }
  .btn-wrapper {
    display: flex;
    align-items: center;
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .title-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .input-group-prepend:not(:first-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .create-custom-field {
    display: flex;
    flex-wrap: wrap;

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    /* .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    } */
    /* .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    } */
  }
}
/* Search form style */
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px){
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
.btn-delete-all {
  font-size: 14px;
  color: #595959;
}
.btn-delete-all .material-symbols-outlined{
  font-size: 20px;
}
.send-campaign {
  width: auto !important;
  font-size: 14px;
  color: #595959;
  font-weight: normal !important;
  margin-left: 15px !important;
}
.send-campaign .dropdown  {
  margin-right: 10px;
}
.btn-add {
  display: flex;
}
.btn-add span{
  color: #6B23DC;
  font-size: 16px !important;
  cursor: pointer;
  display: block;
  font-weight: normal !important;
}
.btn-add .material-symbols-outlined {
  margin-top: 4px;
  margin-left: 5px;
}
.create-contact {
  padding-top: 10px;
}
.new-contacts .all-actions .extra img.last {
  width: 15px;
}

.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}
.rebranding .card {
  box-shadow: none;
}
.card.card-tabs .card-header .nav.nav-tabs{
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs {
  margin-bottom: 5px;
}
.nav-tabs{
  border-bottom: 4px solid #6B23DC3D;
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active{
  border-bottom: solid 4px var(--clr-yup-purple);
}
.nav-tabs .nav-item{
  margin-bottom: -4px;
}
.thin-icon *, .thin-icon {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
.content-row {
  width: 100%;
  display: flex;
  background-color: var(--background-2);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;
  padding-left: 0;
  padding-right: 0;
  >div {
    display: flex;
    align-items: center;
    min-height: 52px;
  }
  /* @media (min-width: 768px) {
    div:last-child {
      justify-content: center;
    }
  } */
}
.option-wrapper {
  margin-left: auto;

  .btn {
    padding: 0;
    height: auto;
    color: var(--gray-font-color-4);
    &:first-of-type {
      margin-right: .5rem;
    }
  }
}
.text-item {
  margin-left: .5rem;
  margin-bottom: 0;
}
.span-contact {
  background: #FCF2E6;
  padding: 1px 8px 1px 8px;
  border-radius: 4px;
  color: #AA5B00;
  font-size: 12px;
}
.right-slide-down {
  margin-left: 10px;
  margin-top: 10px;
}
.expand-icon {
  font-size: 13px;
  position: absolute;
  left: 10px;
  top: 10px;
  color: #595959;
  font-weight: 400;
  cursor: pointer;
}
.expand-icon .material-icons-outlined {
  font-size: 30px;
  float: left;
  margin-top: -4px;
}
.img-preview {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 3px var(--clr-yup-purple);
  background-position: top !important;
  background-size: cover !important;
  margin: 0 auto;
}
.img-preview-large {
  height: 150px;
  width: 100%;
  background-position: top !important;
  background-size: cover !important;
  margin: 0 auto;
}
.table-placeholder {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
}
</style>
